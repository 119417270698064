import React from "react";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export default function ClosedEyeCustom(props: IProps) {
  // this value doesn't exist in our design system, but approved by Quinn
  // as something in-between secondary and minimal for higher contrast
  const fill = "rgba(163, 163, 163, 0.5)";

  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
    >
      <path
        d="M3.56675 10.3802C4.90008 11.2857 6.37786 11.7385 8.00008 11.7385C9.6223 11.7385 11.1001 11.2857 12.4334 10.3802C13.7667 9.47464 14.7334 8.26075 15.3334 6.73853H13.8667C13.3112 7.86075 12.5084 8.75241 11.4584 9.41353C10.4084 10.0746 9.25564 10.4052 8.00008 10.4052C6.74453 10.4052 5.59175 10.0746 4.54175 9.41353C3.49175 8.75241 2.68897 7.86075 2.13341 6.73853H0.666748C1.26675 8.26075 2.23341 9.47464 3.56675 10.3802Z"
        fill={fill}
      />
      <rect x="8.8894" y="11.5532" width="1.47337" height="2.75" transform="rotate(-10 8.8894 11.5532)" fill={fill} />
      <rect x="3.16284" y="9.84192" width="1.47337" height="2.75" transform="rotate(40 3.16284 9.84192)" fill={fill} />
      <rect x="11.7087" y="10.7889" width="1.47337" height="2.75" transform="rotate(-40 11.7087 10.7889)" fill={fill} />
      <rect x="5.65967" y="11.2974" width="1.47337" height="2.75" transform="rotate(10 5.65967 11.2974)" fill={fill} />
    </svg>
  );
}
