import { useAutoScroll } from "@/hooks/useAutoScroll";
import { nullableLibraryComponentFamilyAtom } from "@/stores/Components";
import { reorderTextItemsActionAtom } from "@/stores/Editing";
import { INavTextItem } from "@/stores/Project";
import {
  draggableItemsForTextItemAtom,
  onTextItemClickActionAtomFamily,
  selectionTypeAtom,
  textItemIsSelectedAtomFamily,
} from "@/stores/ProjectSelection";
import { textItemFamilyAtom } from "@/stores/TextItem";
import { DragLocation } from "@ds/atoms/DragAndDroppable";
import Icon from "@ds/atoms/Icon";
import Text from "@ds/atoms/Text";
import TextIcon from "@ds/icons/TextIcon";
import LibraryComponentNameSpan from "@ds/molecules/LibraryComponentNameSpan";
import NavItem from "@ds/molecules/NavItem";
import { ITipTapRichText } from "@shared/types/TextItem";
import { useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { memo, useCallback } from "react";
import { DragStartEvent } from "react-aria";
import { z } from "zod";
import { leftSidebarScrollRefAtom } from "..";
import { textItemListScrollRefAtom } from "../../../TextItemList";

const TextNavItem = memo(function TextNavItem(props: {
  item: INavTextItem;
  isNested?: boolean;
  parentSelected?: boolean;
  disabled?: boolean;
  disableCustomPreview?: boolean;
}) {
  const textItem = useAtomValue(textItemFamilyAtom(props.item._id));
  const textItemScrollContainer = useAtomValue(textItemListScrollRefAtom);
  const leftSidebarScrollContainer = useAtomValue(leftSidebarScrollRefAtom);
  const isSelected = useAtomValue(textItemIsSelectedAtomFamily(props.item._id));
  const selectionType = useAtomValue(selectionTypeAtom);
  const component = useAtomValue(nullableLibraryComponentFamilyAtom(textItem.ws_comp));

  const reorderTextItemsAction = useSetAtom(reorderTextItemsActionAtom);
  const onTextItemClick = useSetAtom(onTextItemClickActionAtomFamily(props.item._id));

  const getDraggableItems = useAtomCallback(
    useCallback((get) => get(draggableItemsForTextItemAtom(props.item._id)), [props.item._id])
  );

  const scrollProps = useAutoScroll(textItemScrollContainer, leftSidebarScrollContainer);

  const handleDrop = useCallback(
    (textItemIds: string[], dragLocation: DragLocation) => {
      reorderTextItemsAction([
        {
          textItemIds: textItemIds,
          blockId: textItem.blockId,
          before: dragLocation === "above" ? props.item._id : undefined,
          after: dragLocation === "below" ? props.item._id : undefined,
        },
      ]);
    },
    [reorderTextItemsAction, textItem.blockId, props.item._id]
  );

  /**
   * If this text item was not already selected, ensure that it becomes the only text item selected.
   */
  const handleDragStart = useCallback(
    function handleDragStart(e: DragStartEvent) {
      if (!isSelected) {
        onTextItemClick({ richText: textItem.rich_text, e });
      }
    },
    [isSelected, onTextItemClick, textItem.rich_text]
  );

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onTextItemClick({ richText: textItem.rich_text, skipInlineEdit: true, e });
    },
    [onTextItemClick, textItem.rich_text]
  );

  return (
    <NavItem
      id={props.item._id}
      type="item"
      icon={!component && <Icon size="xs" Icon={<TextIcon />} />}
      dragAndDrop={{
        ...scrollProps,
        allowedItemKeys: { "ditto/textItem": z.string() },
        selectionType,
        getDraggableItems,
        onDrop: handleDrop,
        onDragStart: handleDragStart,
      }}
      selected={isSelected}
      onClick={onClick}
      showDropIndicators
      isNested={props.isNested}
      parentSelected={props.parentSelected}
      disabled={props.disabled}
      disableCustomPreview={props.disableCustomPreview}
      dropTargetType="text"
    >
      {component ? (
        <LibraryComponentNameSpan size="small" iconSize="xxs" weight="light">
          {component.name}
        </LibraryComponentNameSpan>
      ) : (
        <FormattedTextItemRichText richText={textItem.rich_text} />
      )}
    </NavItem>
  );
});

const FormattedTextItemRichText = memo(function textItemFormattedTextJSX(props: { richText: ITipTapRichText }) {
  return (
    <Text truncate size="small">
      {props.richText.content
        .reduce<(string | { variableName: string })[]>((acc, paragraph) => {
          for (const content of paragraph.content || []) {
            if (content.type === "text") {
              if (typeof acc[acc.length - 1] === "string") {
                acc[acc.length - 1] += content.text;
              } else {
                acc.push(content.text);
              }
            } else {
              acc.push({ variableName: content.attrs.text });
            }
          }

          return acc;
        }, [])
        .map((part, index) => {
          if (typeof part === "string") {
            return (
              <Text size="small" weight="light" key={index}>
                {part}
              </Text>
            );
          } else {
            return (
              <Text size="small" color="variable" key={index}>
                {part.variableName}
              </Text>
            );
          }
        })}
    </Text>
  );
});

export default TextNavItem;
