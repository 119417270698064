import React, { ForwardRefExoticComponent, RefAttributes } from "react";
import { RichTextInputProps, RichTextInputRef } from "../../../../shared/types/RichText";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IProps extends RichTextInputProps {
  RichTextInputComponent: ForwardRefExoticComponent<RichTextInputProps & RefAttributes<RichTextInputRef>>;
  setRef: React.Ref<RichTextInputRef>;

  /**
   * Optional prop for displaying a tutorial tooltip.
   */
  tutorialTooltip?: React.FC<{ children: React.ReactNode }>;
}

function RichTextInputSection(props: IProps) {
  const { RichTextInputComponent, setRef, ...inputProps } = props;

  return (
    <div className={style.textDetails}>
      <div className={style.firstRow}>
        <Text size="small" weight="strong">
          Text
        </Text>

        {/* TODO: https://linear.app/dittowords/issue/DIT-8223/re-implement-suggested-edits-in-ns */}
        {/* <button className={style.editingDropdown}>
              Editing <Icon Icon={<KeyboardArrowDownIcon />} size="small" />
            </button> */}
      </div>
      <RichTextInputComponent ref={setRef} {...inputProps} />
    </div>
  );
}

export default RichTextInputSection;
