import { handleLibraryActivityUpdatedActionAtom } from "@/stores/Activity";
import {
  handleCommentCreatedActionAtom,
  handleCommentUpdatedActionAtom,
  resolveUnresolveCommentActionAtom,
} from "@/stores/Comments";
import {
  handleLibraryComponentFolderCreatedActionAtom,
  handleLibraryComponentFolderDeletedActionAtom,
  handleLibraryComponentFolderReorderedActionAtom,
  handleLibraryComponentFolderUpdatedActionAtom,
} from "@/stores/ComponentFolders";
import {
  handleLibraryComponentsDeletedActionAtom,
  handleLibraryComponentsUpdatedActionAtom,
} from "@/stores/Components";
import { fetchAndInsertNewLibraryComponentActionAtom, moveLibraryComponentsFrontendActionAtom } from "@/stores/Library";
import {
  handleBlocksDeletedActionAtom,
  handleFigmaTextNodesUnlinkedActionAtom,
  handleTextItemInstancesSplitActionAtom,
  handleTextItemsLinkedActionAtom,
  handleUpdateBlocksActionAtom,
  newTextItemsCreatedActionAtom,
  textItemsDeletedActionAtom,
  textItemsUpdatedActionAtom,
} from "@/stores/Project";
import { refreshVariantsAtom } from "@/stores/Workspace";
import * as DittoEvents from "@shared/ditto-events";
import { useDittoEventListener } from "@shared/ditto-events/frontend";
import client from "@shared/frontend/http/httpClient";
import { useSetAtom } from "jotai";

/**
 * This should be rendered near the root level of the web app since library component / component folder
 * information is shared across the entire front-end.
 */
export default function LibraryDittoEventsHandler() {
  const handleLibraryComponentsUpdated = useSetAtom(handleLibraryComponentsUpdatedActionAtom);
  useDittoEventListener(DittoEvents.libraryComponentsUpdated, async function handleLibraryComponentUpdated(data) {
    handleLibraryComponentsUpdated(data);
  });
  useDittoEventListener(DittoEvents.libraryComponentsVariantRemoved, async function handleTextItemVariantRemoved(data) {
    handleLibraryComponentsUpdated(data);
  });

  const handleLibraryComponentCreatedAction = useSetAtom(fetchAndInsertNewLibraryComponentActionAtom);
  useDittoEventListener(DittoEvents.libraryComponentCreated, async function handleLibraryComponentCreated(data) {
    handleLibraryComponentCreatedAction({ componentId: data.componentId });
  });

  const moveLibraryComponentsFrontendAction = useSetAtom(moveLibraryComponentsFrontendActionAtom);
  useDittoEventListener(DittoEvents.libraryComponentsMoved, async function handleLibraryComponentsMoved(data) {
    moveLibraryComponentsFrontendAction({ actions: data.actions, options: { isWebsocketEvent: true } });
  });

  const handleLibraryComponentFolderCreated = useSetAtom(handleLibraryComponentFolderCreatedActionAtom);
  useDittoEventListener(DittoEvents.libraryComponentFolderCreated, async function handleLibraryComponentCreated(data) {
    handleLibraryComponentFolderCreated({ newFolderId: data.folderId, newFolderParentId: data.parentFolderId });
  });

  const handleLibraryComponentFolderUpdatedAction = useSetAtom(handleLibraryComponentFolderUpdatedActionAtom);
  useDittoEventListener(
    DittoEvents.libraryComponentFolderUpdated,
    async function handleLibraryComponentFolderUpdated(data) {
      handleLibraryComponentFolderUpdatedAction({
        folderId: data.folderId,
        name: data.name,
        parentId: data.parentId,
      });
    }
  );

  const handleLibraryComponentFolderDeletedAction = useSetAtom(handleLibraryComponentFolderDeletedActionAtom);
  useDittoEventListener(
    DittoEvents.libraryComponentFolderDeleted,
    async function handleLibraryComponentFolderDeleted(data) {
      handleLibraryComponentFolderDeletedAction({
        folderId: data.folderId,
        folderName: data.folderName,
        idToKeyMap: data.idToKeyMap,
      });
    }
  );

  const handleLibraryComponentFolderReorderedAction = useSetAtom(handleLibraryComponentFolderReorderedActionAtom);
  useDittoEventListener(
    DittoEvents.libraryComponentFolderReordered,
    async function handleLibraryComponentFolderReordered(data) {
      handleLibraryComponentFolderReorderedAction({
        folderId: data.folderId,
        idToKeyMap: data.idToKeyMap,
      });
    }
  );

  const handleLibraryComponentsDeleted = useSetAtom(handleLibraryComponentsDeletedActionAtom);
  useDittoEventListener(DittoEvents.libraryComponentsDeleted, (data) => handleLibraryComponentsDeleted(data));

  const updateLibraryActivity = useSetAtom(handleLibraryActivityUpdatedActionAtom);
  useDittoEventListener(DittoEvents.libraryActualChangesCreated, (data) => {
    updateLibraryActivity(data.changeItemIds);
  });

  // MARK: Comments

  const handleCommentCreatedAction = useSetAtom(handleCommentCreatedActionAtom);
  useDittoEventListener(DittoEvents.libraryCommentThreadCreated, async function handleCommentCreated(data) {
    const [newComment] = await client.comments.getCommentsByCommentIds({
      commentIds: [data.commentThreadId],
      type: "standard",
    });

    if (newComment) {
      handleCommentCreatedAction(newComment);
    }
  });

  const handleCommentUpdatedAction = useSetAtom(handleCommentUpdatedActionAtom);
  useDittoEventListener(DittoEvents.libraryCommentThreadUpdated, async function handleCommentUpdated(data) {
    const [newComment] = await client.comments.getCommentsByCommentIds({
      commentIds: [data.commentThreadId],
      type: "standard",
    });

    if (newComment) {
      handleCommentUpdatedAction(newComment);
    }
  });

  const handleCommentResolutionUpdatedAction = useSetAtom(resolveUnresolveCommentActionAtom);
  useDittoEventListener(
    DittoEvents.libraryCommentThreadResolutionUpdated,
    async function handleCommentResolutionUpdated(data) {
      handleCommentResolutionUpdatedAction(
        { commentThreadId: data.commentThreadId, newResolvedValue: data.isResolved },
        { localOnly: true }
      );
    }
  );

  // MARK: - Variant created

  const refreshVariants = useSetAtom(refreshVariantsAtom);

  useDittoEventListener(DittoEvents.variantCreated, async function handleVariantCreated(data) {
    refreshVariants();
  });

  // MARK: - Blocks updated

  const handleUpdateBlocksAction = useSetAtom(handleUpdateBlocksActionAtom);

  useDittoEventListener(DittoEvents.projectBlocksUpdated, async function handleBlocksUpdated(data) {
    handleUpdateBlocksAction(data);
  });

  const handleBlocksDeletedAction = useSetAtom(handleBlocksDeletedActionAtom);

  useDittoEventListener(DittoEvents.projectBlocksDeleted, async function handleBlocksDeleted(data) {
    handleBlocksDeletedAction(data);
  });

  // MARK: - Text Items created

  const newTextItemsCreatedAction = useSetAtom(newTextItemsCreatedActionAtom);

  useDittoEventListener(DittoEvents.textItemsCreated, async function handleTextItemsCreated(data) {
    newTextItemsCreatedAction(data);
  });

  // MARK: - Text Items updated

  const textItemsUpdatedAction = useSetAtom(textItemsUpdatedActionAtom);

  useDittoEventListener(DittoEvents.textItemsUpdated, async function handleTextItemsUpdated(data) {
    textItemsUpdatedAction(data);
  });

  // MARK: - Text Items Linked

  const handleTextItemsLinkedAction = useSetAtom(handleTextItemsLinkedActionAtom);

  useDittoEventListener(DittoEvents.libraryComponentLinked, async function handleTextItemsLinked(data) {
    handleTextItemsLinkedAction(data);
  });

  // MARK: Unlinking Figma Nodes

  const handleTextItemInstancesSplitAction = useSetAtom(handleTextItemInstancesSplitActionAtom);

  useDittoEventListener(DittoEvents.textItemInstancesSplit, async function handleTextItemInstancesSplit(data) {
    handleTextItemInstancesSplitAction(data);
  });

  // MARK: Disconnecting Figma Nodes

  const handleFigmaTextNodesUnlinkedAction = useSetAtom(handleFigmaTextNodesUnlinkedActionAtom);

  useDittoEventListener(DittoEvents.figmaTextNodesUnlinked, async function handleUnlinkNodes(data) {
    handleFigmaTextNodesUnlinkedAction(data);
  });

  // MARK: - Text Items delete
  const textItemsDeletedAction = useSetAtom(textItemsDeletedActionAtom);

  useDittoEventListener(DittoEvents.textItemsDeleted, async function handleTextItemsDeleted(data) {
    textItemsDeletedAction(data);
  });

  // MARK: - Text item variant removed

  useDittoEventListener(DittoEvents.textItemVariantRemoved, async function handleTextItemVariantRemoved(data) {
    textItemsUpdatedAction({
      textItemIds: [data.textItemId],
      clientId: data.clientId,
    });
  });

  return <></>;
}
