import { ZObjectId } from "@shared/types/lib";
import { FilterQuery } from "mongoose";
import { z } from "zod";

// #region Developer ID Validation

const ZDevIdValidationErrors = {
  empty: z.object({ isValid: z.literal(false), type: z.literal("empty"), message: z.string() }),
  maxLength: z.object({
    isValid: z.literal(false),
    type: z.literal("maxLength"),
    message: z.string(),
    length: z.number(),
  }),
  invalidChars: z.object({
    isValid: z.literal(false),
    type: z.literal("invalidChars"),
    message: z.string(),
    invalidChars: z.array(z.string()),
  }),
};

const ZDevIdValidationResponses = {
  ...ZDevIdValidationErrors,
  valid: z.object({ isValid: z.literal(true) }),
};

export const ZValidateDeveloperIdResult = z.union([
  ZDevIdValidationResponses.valid,
  ZDevIdValidationResponses.empty,
  ZDevIdValidationResponses.maxLength,
  ZDevIdValidationResponses.invalidChars,
]);
export type IValidateDeveloperIdResult = z.infer<typeof ZValidateDeveloperIdResult>;

const ZUpdateDevIdErrorTypes = {
  ...ZDevIdValidationErrors,
  notFound: z.object({
    type: z.literal("notFound"),
    message: z.string(),
  }),
  projectExists: z.object({
    type: z.literal("exists"),
    message: z.string(),
    projectId: ZObjectId,
  }),
  textItemExists: z.object({
    type: z.literal("exists"),
    message: z.string(),
    textItemId: ZObjectId,
    projectId: ZObjectId,
  }),
};

export const ZUpdateProjectDevIdError = z.discriminatedUnion("type", [
  ZUpdateDevIdErrorTypes.notFound,
  ZUpdateDevIdErrorTypes.empty,
  ZUpdateDevIdErrorTypes.maxLength,
  ZUpdateDevIdErrorTypes.invalidChars,
  ZUpdateDevIdErrorTypes.projectExists,
]);
export type IUpdateProjectDevIdError = z.infer<typeof ZUpdateProjectDevIdError>;

export const ZUpdateTextItemDevIdError = z.discriminatedUnion("type", [
  ZUpdateDevIdErrorTypes.notFound,
  ZUpdateDevIdErrorTypes.empty,
  ZUpdateDevIdErrorTypes.maxLength,
  ZUpdateDevIdErrorTypes.invalidChars,
  ZUpdateDevIdErrorTypes.textItemExists,
]);
export type IUpdateTextItemDevIdError = z.infer<typeof ZUpdateTextItemDevIdError>;

// #endregion Developer ID Validation

export const ZApiErrorWithMessage = z.object({
  message: z.string(),
});
export type IApiErrorWithMessage = z.infer<typeof ZApiErrorWithMessage>;

export const ZApiErrorWithStatusAndMessage = z.object({
  message: z.string(),
  status: z.number().int().min(100).max(599), // HTTP status codes range from 100 to 599
});
export type IApiErrorWithStatusAndMessage = z.infer<typeof ZApiErrorWithStatusAndMessage>;

export const ZApiErrorWithProjectIds = z.object({
  projectIds: z.array(ZObjectId),
});
export type IApiErrorWithProjectIds = z.infer<typeof ZApiErrorWithProjectIds>;

export const ZApiErrorWithFolderIds = z.object({
  folderIds: z.array(z.string()),
});
export type IApiErrorWithFolderIds = z.infer<typeof ZApiErrorWithFolderIds>;

export const ZApiErrorWithFolderId = z.object({
  folderId: z.string(),
});
export type IApiErrorWithFolderId = z.infer<typeof ZApiErrorWithFolderId>;

export const ZApiErrorWithKeys = z.object({
  keys: z.array(z.literal("components")),
});
export type IApiErrorWithKeys = z.infer<typeof ZApiErrorWithKeys>;

export const ZApiErrorWithMissingKeys = z.object({
  missingKeys: z.array(z.string()),
});
export type IApiErrorWithMissingKeys = z.infer<typeof ZApiErrorWithMissingKeys>;

export const ZApiErrorExpectedResults = z.object({
  expected: z.string(),
  received: z.string(),
});
export type IApiErrorExpectedResults = z.infer<typeof ZApiErrorExpectedResults>;

export const ZApiErrorKeyValue = z.object({
  key: z.string(),
  value: z.any(),
});
export type IApiErrorKeyValue = z.infer<typeof ZApiErrorKeyValue>;

export type IApiErrorWithFilter<T> = { filter: FilterQuery<T> };

export const ZPublicApiInvalidIdsError = z.object({
  invalidProjectIds: z.array(z.string()).optional(),
  invalidVariantIds: z.array(z.string()).optional(),
});
export type IPublicApiInvalidIdsError = z.infer<typeof ZPublicApiInvalidIdsError>;
