import useSegment from "@/hooks/useSegment";
import { libraryComponentFoldersListAtom } from "@/stores/ComponentFolders";
import {
  derivedOnlySelectedComponentIdAtom,
  LibraryDetailsEditPanelTabAtom,
  reorderLibraryComponentsActionAtom,
  selectedComponentIdsAtom,
  selectedLibraryFolderIdAtom,
} from "@/stores/Library";
import DeveloperId from "@/views/NS/components/DeveloperId";
import Button from "@ds/atoms/Button";
import DialogueModal from "@ds/molecules/DialogueModal";
import LibraryComponentFolderFilterDropdown, {
  ALL_COMPONENTS,
} from "@ds/molecules/LibraryComponentFolderFilterDropdown";
import LoadingMessage from "@ds/molecules/LoadingMessage";
import Scrollbar from "@ds/molecules/Scrollbar";
import * as SegmentEvents from "@shared/segment-event-names";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import React, { Suspense, useCallback } from "react";
import { deleteLibraryComponentsStructureAtom } from "../../../../../stores/Components";
import DeleteItem from "../../../components/DeleteItem";
import LibraryCommentsSection from "../LibraryComponentCommentsSection";
import LibraryComponentInstancesBanner from "../LibraryComponentInstancesBanner";
import LibraryMetadataPanel from "../LibraryComponentMetadata";
import style from "./style.module.css";
// Library edit panel should only appear in one place so having this atom here
// should be fine
const targetFolderAtom = atom<{ _id: string; name: string } | null>(null);

function MoveComponentToFolderModalContent() {
  const [targetFolder, setTargetFolder] = useAtom(targetFolderAtom);
  const currentFolderId = useAtomValue(selectedLibraryFolderIdAtom);

  return (
    <div className={style.moveComponentToFolderModalContent}>
      <LibraryComponentFolderFilterDropdown
        selectedFolder={targetFolder}
        onChange={(folder) => {
          if (folder !== currentFolderId) {
            setTargetFolder(folder);
          }
        }}
        foldersAtom={libraryComponentFoldersListAtom}
        dontShowCurrentFolder={true}
        currentFolderId={currentFolderId}
      />
    </div>
  );
}

function LibraryEditEditPanel() {
  const derivedOnlySelectedComponentId = useAtomValue(derivedOnlySelectedComponentIdAtom);
  const selectedComponentIds = useAtomValue(selectedComponentIdsAtom);
  const deleteLibraryComponentAction = useSetAtom(deleteLibraryComponentsStructureAtom);
  const setSelectedTab = useSetAtom(LibraryDetailsEditPanelTabAtom);
  const reorderLibraryComponentsAction = useSetAtom(reorderLibraryComponentsActionAtom);
  const { track } = useSegment();

  const [confirmMoveComponentToFolder, moveComponentToFolderModalProps] = DialogueModal.useConfirmationModal({
    content: <MoveComponentToFolderModalContent />,
    headline: "Move to folder",
    actionText: "Move",
  });
  const [confirmShouldDeleteComponent, deleteComponentModalProps] = DialogueModal.useConfirmationModal({
    content:
      selectedComponentIds.length === 1
        ? `This component and all of its data will be deleted. Any text items linked to this component in projects will be unlinked. This can't be undone.`
        : `${selectedComponentIds.length} components and all of their data will be deleted. Any text items linked to these components in projects will be unlinked. This can't be undone.`,
    headline: `Delete ${selectedComponentIds.length === 1 ? "component" : "components"}?`,
    actionText: "Delete",
  });

  // Use useAtomCallback to ensure that the target folder is always up to date
  // otherwise if we do useAtomValue for targetFolderAtom, the target folder
  // will be stale due to the await call for the modal
  const onMoveComponentToFolder = useAtomCallback(
    useCallback(
      async (get) => {
        const shouldMove = await confirmMoveComponentToFolder();

        let folderId: string | undefined | null = get(targetFolderAtom)?._id;

        if (folderId === ALL_COMPONENTS.value) {
          folderId = null;
        }

        if (shouldMove) {
          reorderLibraryComponentsAction([
            {
              componentIds: selectedComponentIds,
              folderId,
            },
          ]);
        }
      },
      [confirmMoveComponentToFolder, reorderLibraryComponentsAction, selectedComponentIds]
    )
  );

  const onDelete = useCallback(async () => {
    const shouldDelete = await confirmShouldDeleteComponent();

    if (shouldDelete) {
      await deleteLibraryComponentAction({ ids: selectedComponentIds });
    }
  }, [confirmShouldDeleteComponent, deleteLibraryComponentAction, selectedComponentIds]);

  const onInstancesBannerActionClick = useCallback(() => {
    setSelectedTab("INSTANCES");
    track({
      event: SegmentEvents.DESIGN_PREVIEW_OPENED,
      properties: { from: "banner", version: "NS", location: "library" },
    });
  }, [setSelectedTab, track]);

  return (
    <Scrollbar className={style.scrollArea} disableScrollX>
      <DialogueModal {...moveComponentToFolderModalProps} type="default" />
      <DialogueModal {...deleteComponentModalProps} type="danger" />
      <Suspense fallback={null}>
        <LibraryComponentInstancesBanner onActionClick={onInstancesBannerActionClick} />
      </Suspense>
      <Suspense fallback={<LoadingMessage text="Loading Metadata..." className={style.loadingContainer} />}>
        <LibraryMetadataPanel />
      </Suspense>

      {/* These elements should only be rendered if exactly one text item is selected */}
      {!!derivedOnlySelectedComponentId && (
        <>
          <Suspense fallback={<></>}>
            <LibraryCommentsSection />
          </Suspense>
          <DeveloperId />
          <div className={style.actionButtonsContainer}>
            <Button onClick={onMoveComponentToFolder} level="subtle" size="micro" className={style.moveToFolderButton}>
              Move to folder
            </Button>
            <DeleteItem onClick={onDelete} text="Delete component" />
          </div>
        </>
      )}
      {!derivedOnlySelectedComponentId && (
        <div className={style.actionButtonsContainer}>
          <Button onClick={onMoveComponentToFolder} level="subtle" size="micro" className={style.moveToFolderButton}>
            Move to folder
          </Button>
          <DeleteItem onClick={onDelete} text="Delete components" />
        </div>
      )}
    </Scrollbar>
  );
}

export default LibraryEditEditPanel;
