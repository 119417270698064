import { UserInvitation } from "@/components/permissions/InviteUsersInput/types";
import { IDittoProject } from "@shared/types/DittoProject";
import { IFigmaGroup } from "@shared/types/FigmaGroup";
import {
  GetSyncedFigmaPagesResponse,
  IGetFramePreviewsData,
  ISyncedTextNodesMapData,
  ITextItemsMapData,
} from "@shared/types/http/DittoProject";
import { IFigmaSyncRequestBody } from "@shared/types/http/figma";
import { IObjectId } from "@shared/types/lib";
import { InviteToken } from "../../schema/inviteToken.types";
import { createHttpRequest } from "./lib/createHttpRequest";

export const inviteUsersToProject = createHttpRequest<
  { projectId: string; invitations: UserInvitation[]; message: string },
  { invites: Pick<InviteToken, "email" | "role" | "permissionGroups" | "data">[] }
>({
  method: "post",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/invite`;
  },
  getConfig({ invitations, message }) {
    return {
      data: {
        invitations,
        message,
      },
    };
  },
});

export const getAllTags = createHttpRequest<{ projectId: string }, string[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/tags`;
  },
  getConfig({ projectId }) {
    return {
      params: { projectId },
    };
  },
});

export const getActivePreviewsJob = createHttpRequest<{ projectId: string }, { jobId: string | null }>({
  method: "get",
  getUrl({ projectId }) {
    return `/jobs/ditto-project-figma-previews/${projectId}`;
  },
});

export const getTopLevelFramesMap = createHttpRequest<{ projectId: string }, Record<string, IFigmaGroup[]>>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items/top-level-frames`;
  },
});

export const getFramePreviewsMap = createHttpRequest<{ projectId: string }, IGetFramePreviewsData>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items/frame-previews`;
  },
});

export const getTextItemsMap = createHttpRequest<{ projectId: string }, ITextItemsMapData>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items-map`;
  },
});

export const getSyncedTextNodesMap = createHttpRequest<{ projectId: string }, ISyncedTextNodesMapData>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/synced-text-nodes-map`;
  },
});

export const moveProjectToFolder = createHttpRequest<{ projectId: string; folderId: string | null }, IDittoProject>({
  method: "put",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/move-to-folder`;
  },
  getConfig({ folderId }) {
    return {
      data: { folderId },
    };
  },
});

export const getSyncedFigmaPages = createHttpRequest<{ projectId: string }, GetSyncedFigmaPagesResponse>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/synced-figma-pages`;
  },
});

export const syncFigma = createHttpRequest<IFigmaSyncRequestBody, { jobId: string }>({
  method: "post",
  url: "/figma/sync",
  getUrl({ projectId }) {
    return `/figma/sync/${projectId}`;
  },
  getConfig(data) {
    return { data };
  },
});

export const removeVariant = createHttpRequest<
  { variantId: IObjectId; projectId: IObjectId; textItemIds: IObjectId[] },
  void
>({
  method: "patch",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items/remove-variant`;
  },
  getConfig({ variantId, textItemIds }) {
    return {
      data: { variantId, textItemIds },
    };
  },
});
