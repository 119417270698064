import {
  libraryComponentFamilyAtom,
  nullableLibraryComponentFamilyAtom,
  updateLibraryComponentActionAtom,
} from "@/stores/Components";
import { textItemFamilyAtom } from "@/stores/TextItem";
import { NO_ITEMS_VALUE } from "@ds/molecules/BaseCombobox";
import { fetchAutoName } from "@ds/molecules/LibraryComponentAutoNameInput";
import { DEFAULT_RICH_TEXT } from "@shared/common/constants";
import client from "@shared/frontend/http/httpClient";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import atomWithDebounce from "@shared/frontend/stores/atomWithDebounce";
import { cacheAtom } from "@shared/frontend/stores/cacheAtom";
import { showToastActionAtom } from "@shared/frontend/stores/Toast";
import { ILibraryComponent } from "@shared/types/LibraryComponent";
import { ITextItemStatus, ZTextItemFilterableFields } from "@shared/types/TextItem";
import logger from "@shared/utils/logger";
import ObjectId from "bson-objectid";
import { atom } from "jotai";
import { derive, soon } from "jotai-derive";
import { unwrap } from "jotai/utils";
import { libraryComponentFolderFamilyAtom } from "./ComponentFolders";
import { projectIdAtom } from "./Project";
import {
  clearLibraryInteractionViewActionAtom,
  derivedOnlySelectedTextItemAtom,
  detailsPanelSelectionStateAtom,
  selectedTextItemIdsAtom,
} from "./ProjectSelection";

// MARK:- Linking filter values
export const selectedStatusesAtom = atom<ITextItemStatus[]>([]);

export const selectedTagsAtom = atom<string[]>([]);

export const selectedProjectIdAtom = atom<string | null>(null);

export const { currentValueAtom: searchQueryAtom, debouncedValueAtom: debouncedSearchQueryAtom } = atomWithDebounce(
  "",
  100
);
export const componentIdsForLinkingAtom = cacheAtom(
  atom(async (get) => {
    get(selectedTextItemIdsAtom); // we add this just as a dependency to force a refetch

    const usedInProjectFilter = get(selectedProjectIdAtom);
    const projectFilter =
      usedInProjectFilter === NO_ITEMS_VALUE || usedInProjectFilter === null ? undefined : usedInProjectFilter;

    const folderId = get(selectedLibraryFolderIdAtom);
    try {
      const data = await client.libraryComponent.searchLibraryComponents({
        folderId: folderId ?? undefined,
        query: get(debouncedSearchQueryAtom),
        statuses: get(selectedStatusesAtom),
        tags: get(selectedTagsAtom),
        projectId: projectFilter,
      });
      return data.componentIds;
    } catch (error) {
      logger.error(
        "Error searching library components",
        {
          context: {
            folderId,
            query: get(debouncedSearchQueryAtom),
            statuses: get(selectedStatusesAtom),
            tags: get(selectedTagsAtom),
            projectId: get(selectedProjectIdAtom),
          },
        },
        error
      );
      return [];
    }
  })
);

export const clearFiltersActionAtom = atom(null, (get, set) => {
  set(selectedStatusesAtom, []);
  set(selectedTagsAtom, []);
  set(debouncedSearchQueryAtom, "");
});

export const selectedLibraryFolderIdAtom = atom<string | null>(null);

export const selectedFolderAtom = atom((get) => {
  const folderId = get(selectedLibraryFolderIdAtom);
  if (!folderId) return null;
  return soon(get(libraryComponentFolderFamilyAtom(folderId)), (folder) => folder ?? null);
});

export const selectedComponentIdAtom = atom<string | null>(null);
export const selectedSuggestedComponentIdAtom = atom<string | null>(null);

export const selectedComponentAtom = atom((get) => {
  const componentId = get(selectedComponentIdAtom);
  if (!componentId) return null;
  return get(libraryComponentFamilyAtom(componentId));
});

export const {
  valueAtom: componentNameAtom,
  hasChanged: componentNameHasChangedAtom,
  refreshAtom: regenerateComponentNameActionAtom,
  resetAtom: resetComponentNameAtom,
} = asyncMutableDerivedAtom({
  loadData: async (get) => {
    const selectedTextItem = await get(derivedOnlySelectedTextItemAtom);
    if (!selectedTextItem) return "";
    return fetchAutoName(client, selectedTextItem.text, selectedTextItem.variables);
  },
});

// MARK:- Derived Atoms

// used for the purposes of surfacing a "are you sure you want to change selection" modal
export const hasLinkingFlowChangesAtom = atom((get) => {
  const selectedComponentId = get(selectedComponentIdAtom);
  const selectedSuggestedComponentId = get(selectedSuggestedComponentIdAtom);
  if (selectedComponentId || selectedSuggestedComponentId) return true;

  return false;
});

// used for the purposes of surfacing a "are you sure you want to change selection" modal
export const hasPublishingFlowChangesAtom = atom((get) => {
  const componentNameHasChanged = get(componentNameHasChangedAtom);
  if (componentNameHasChanged) return true;
  return false;
});

// MARK:- Actions

export const linkComponentActionAtom = atom(null, async (get, set, componentId: string) => {
  const selectedTextItemIds = get(selectedTextItemIdsAtom);
  const selectedTextItems = await Promise.all(
    selectedTextItemIds.map((textItemId) => get(textItemFamilyAtom(textItemId)))
  );
  const component = await get(nullableLibraryComponentFamilyAtom(componentId));
  const projectId = get(projectIdAtom);
  if (!componentId || !component || !projectId || !selectedTextItemIds.length) return;

  // create deep copes of the text items so we can revert the changes if the backend update fails
  const originalTextItems = selectedTextItems.map((textItem) => ({
    ...textItem,
  }));

  // perform frontend state updates
  selectedTextItems.forEach((textItem) => {
    const updatedTextItem = { ...textItem, ws_comp: componentId };

    // set text and metadata to match component -- this Zod schema determines which fields are always
    // kept synced between text items and components.
    const sharedFields = ZTextItemFilterableFields.shape;
    for (const key in sharedFields) {
      updatedTextItem[key] = component[key];
    }

    set(textItemFamilyAtom(textItem._id), updatedTextItem);
  });

  set(updateLibraryComponentActionAtom, {
    _id: componentId,
    update: {
      ...component,
      instances: component.instances.concat(selectedTextItemIds),
    },
  });

  // switch page back to edit
  // Clear these before we switch views -- don't want "unsaved changes"
  set(selectedComponentIdAtom, null);

  set(detailsPanelSelectionStateAtom, "EDIT");
  set(clearLibraryInteractionViewActionAtom);

  // If the user was suggested a component and they are linking to the same
  // component (either through the suggested UI or manually selecting the same component
  // via non-suggested UI), we send that information to the backend for segment tracking
  const suggestedComponentIds = get(unwrappedSuggestedComponentIdsAtom);
  const wasSuggested = suggestedComponentIds.includes(componentId);
  // backend update
  try {
    await client.libraryComponent.linkTextItems({
      componentId,
      projectId,
      textItemIds: selectedTextItemIds,
      wasSuggested,
    });
    set(showToastActionAtom, { message: "Linked text item to component" });
    set(selectedSuggestedComponentIdAtom, null);
  } catch (e) {
    // revert frontend state updates
    for (const textItem of originalTextItems) {
      set(textItemFamilyAtom(textItem._id), textItem);
    }
    set(updateLibraryComponentActionAtom, {
      _id: componentId,
      update: {
        ...component,
        instances: component.instances.filter((instance) => !selectedTextItemIds.includes(instance)),
      },
    });

    set(showToastActionAtom, { message: "Something went wrong linking this component" });
    logger.error(
      "Error linking component",
      { context: { componentId, projectId, textItemIds: selectedTextItemIds } },
      e
    );
    return;
  }
});

export const unlinkComponentActionAtom = atom(null, async (get, set) => {
  const projectId = get(projectIdAtom);
  if (!projectId) return;
  const textItem = await get(derivedOnlySelectedTextItemAtom);
  if (!textItem) return;
  const component = await get(nullableLibraryComponentFamilyAtom(textItem.ws_comp));
  if (!component) return;

  // create deep copy of the text item so we can revert the changes if the backend update fails
  const originalTextItem = { ...textItem };

  // perform frontend state updates
  set(textItemFamilyAtom(textItem._id), {
    ...textItem,
    ws_comp: null,
  });

  set(updateLibraryComponentActionAtom, {
    _id: component._id,
    update: {
      ...component,
      instances: component.instances.filter((instance) => instance !== textItem._id),
    },
  });

  // finally, perform the backend update
  try {
    await client.libraryComponent.unlinkTextItems({
      componentId: component._id,
      projectId,
      textItemIds: [textItem._id],
    });
    set(showToastActionAtom, { message: "Component unlinked successfully" });
  } catch (e) {
    // revert frontend state updates
    set(textItemFamilyAtom(textItem._id), originalTextItem);
    set(updateLibraryComponentActionAtom, {
      _id: component._id,
      update: {
        ...component,
        instances: component.instances.concat(textItem._id),
      },
    });

    set(showToastActionAtom, { message: "Something went wrong unlinking this component" });
    logger.error(
      "Error unlinking component",
      {
        context: {
          componentId: component._id,
          projectId,
          textItemIds: [textItem._id],
        },
      },
      e
    );
    return;
  }
});

function getNewComponentObject(override: Partial<ILibraryComponent>): ILibraryComponent {
  const id = new ObjectId();
  return {
    _id: id.toString(),
    instances: [],
    workspaceId: "",
    name: "",
    apiId: "",
    commentThreads: [],
    folderId: null,
    assignedAt: null,
    editedAt: null,
    editedBy: null,
    characterLimit: null,
    sortKey: "",
    status: "NONE",
    assignee: null,
    text: "",
    rich_text: DEFAULT_RICH_TEXT,
    notes: null,
    tags: [],
    variables: [],
    plurals: [],
    variants: [],
    ...override,
  };
}

export const publishComponentActionAtom = atom(
  null,
  async (get, set, data: { name: string; textItemId: string; folderId?: string }) => {
    const textItem = await get(textItemFamilyAtom(data.textItemId));
    if (!textItem) {
      throw new Error("Cannot publish component without selected text item");
    }

    // create deep copy of the text item so we can revert the changes if the backend update fails
    const originalTextItem = { ...textItem };

    const newComponentObject = getNewComponentObject({
      name: data.name,
      instances: [data.textItemId],
      folderId: data.folderId ?? null,
      workspaceId: textItem.workspace_ID,
    });

    // frontend updates
    for (const key in ZTextItemFilterableFields.shape) {
      newComponentObject[key] = textItem[key];
    }

    libraryComponentFamilyAtom(newComponentObject._id, newComponentObject);

    // we need to do this set *after* the componentFamilyAtom set above, so that the text item in the frontend will
    // properly resolve to the new component object.
    set(textItemFamilyAtom(data.textItemId), {
      ...textItem,
      ws_comp: newComponentObject._id,
    });

    // backend update
    try {
      await client.libraryComponent.publish({
        name: data.name,
        folderId: data.folderId,
        textItemId: data.textItemId,
        newComponentId: newComponentObject._id,
      });
      set(showToastActionAtom, { message: "Published new component to library!" });
    } catch (error) {
      // revert frontend state updates
      set(textItemFamilyAtom(data.textItemId), originalTextItem);

      set(showToastActionAtom, { message: "Something went wrong publishing a new component" });
      logger.error("Error publishing component", { context: { data } }, error);
      return;
    }
  }
);

// Atom for component suggestions associated with the selected text item
// Recomputes when the selection of a single text item changes
const _suggestedComponentIdsAtom = atom(async (get) => {
  return soon(get(derivedOnlySelectedTextItemAtom), async (derivedOnlySelectedTextItem) => {
    if (!derivedOnlySelectedTextItem || derivedOnlySelectedTextItem.ws_comp) return [] as string[];

    try {
      const response = await client.libraryComponent.exactTextMatches({
        textItemId: derivedOnlySelectedTextItem._id,
      });

      // This creates a dependency on the current components that are suggested, so that if they're edited to no longer match the text item,
      // the suggested component ids atom recomputes
      for (const componentId of response.componentIds) {
        await get(nullableLibraryComponentFamilyAtom(componentId));
      }

      return response.componentIds;
    } catch (e) {
      logger.error(
        "Error getting component suggestions",
        { context: { textItemId: derivedOnlySelectedTextItem._id } },
        e
      );
      return [] as string[];
    }
  });
});

export const unwrappedSuggestedComponentIdsAtom = unwrap(_suggestedComponentIdsAtom, (prev) => prev ?? []);

export const hasComponentSuggestionsAtom = derive([unwrappedSuggestedComponentIdsAtom], (suggestedIds) => {
  return suggestedIds.length > 0;
});
