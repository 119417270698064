import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined";
import classNames from "classnames";
import { atom, Atom, PrimitiveAtom, useAtom, useAtomValue, useSetAtom, WritableAtom } from "jotai";
import { AtomFamily } from "jotai/vanilla/utils/atomFamily";
import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import * as SegmentEvents from "../../../../shared/segment-event-names";
import { IHiddenField } from "../../../../shared/types/TextItem";
import Button from "../../atoms/Button";
import ThumbnailBarIcon from "../../atoms/Icon/icons/ThumbnailBarIcon";
import SeparatorLine from "../../atoms/SeparatorLine";
import Text from "../../atoms/Text";
import Toggle from "../../atoms/Toggle";
import AddFiltersDropdown from "../../molecules/AddFiltersDropdown";
import AddGroupingsDropdown, { GroupingType } from "../../molecules/AddGroupingsDropdown";
import AssigneeFilterDropdown, { AssigneeFilterDropdownUser } from "../../molecules/AssigneeFilterDropdown";
import HideFieldsDropdown from "../../molecules/HideFieldsDropdown";
import PageFilterDropdown, { IPageOption } from "../../molecules/PageFilterDropdown";
import StatusFilterDropdown from "../../molecules/StatusFilterDropdown";
import TagsFilterDropdown from "../../molecules/TagsFilterDropdown";
import FilterBarSearch from "../FilterBarSearch";
import style from "./index.module.css";

export const ALL_FILTER_KEYS = ["status", "assignee", "tags", "apiID", "page"] as const;
export type FilterKey = (typeof ALL_FILTER_KEYS)[number];

interface IProps {
  filterContextId: string;
  designPreviewToggledAtom?: PrimitiveAtom<boolean>;
  onDesignPreviewToggledChange?: (designPreviewToggled: boolean) => void;
  searchAtom: WritableAtom<string | null, [newSearch: string | null], void>;
  sidebarCollapseStateAtom?: PrimitiveAtom<CollapseState>; // Will only render the toggle if this atom is provided
  hiddenFieldsListAtom: Atom<IHiddenField[] | null>;
  resetHiddenFieldsActionAtom: WritableAtom<null, [id: string], void>;
  updateHiddenFieldsActionAtom: WritableAtom<
    null,
    [id: string, updatedFields: { value: string; label: string; isHidden: boolean }[]],
    void
  >;
  useSegment: () => {
    track: (args: unknown) => void;
    debouncedTrack: (args: unknown, debounceTime: number) => void;
  };
  // The filters you want to display in the filter bar
  filters: Partial<Record<FilterKey, { value: FilterKey; label: string }>>;
  selectedFiltersValuesAtomFamily: AtomFamily<FilterKey, WritableAtom<string[] | null, [string[] | null], void>>;
  pagesAtom: Atom<IPageOption[] | Promise<IPageOption[]>>;
  selectedFiltersAtom: WritableAtom<FilterKey[], [newSelectedFilterKeys: FilterKey[]], void>;
  usersAtom: Atom<AssigneeFilterDropdownUser[] | Promise<AssigneeFilterDropdownUser[]>>;
  tagsAtom: Atom<string[] | Promise<string[]>>;
  className?: string;
  style?: React.CSSProperties;
  searchPlaceholder?: string;

  selectedGroupingAtom?: WritableAtom<GroupingType | null, [newSelectedGrouping: GroupingType | null], void>;
  onGroupingChange?: (newSelectedGrouping: GroupingType | null) => void;
}

export type CollapseState = "open" | "closed" | "unset";

const _dummyGroupingAtom = atom<GroupingType | null>(null);

export function FilterBar(props: IProps) {
  useAtomValue(props.tagsAtom); // preload tags

  const [selectedFilters, setSelectedFilters] = useAtom(props.selectedFiltersAtom);
  const [statusFilters, setStatusFilters] = useAtom(props.selectedFiltersValuesAtomFamily("status"));
  const [assigneeFilters, setAssigneeFilters] = useAtom(props.selectedFiltersValuesAtomFamily("assignee"));
  const [apiIDFilters, setApiIDFilters] = useAtom(props.selectedFiltersValuesAtomFamily("apiID"));
  const [tagsFilters, setTagsFilters] = useAtom(props.selectedFiltersValuesAtomFamily("tags"));
  const [pageFilters, setPageFilters] = useAtom(props.selectedFiltersValuesAtomFamily("page"));

  const [selectedGrouping, setSelectedGrouping] = useAtom(props.selectedGroupingAtom ?? _dummyGroupingAtom);

  const filterOptions = useMemo(() => Object.values(props.filters), [props.filters]);
  const selectedFilterOptions = useMemo(
    () =>
      selectedFilters.map(
        (filterKey) =>
          ({
            value: props.filters[filterKey]!.value,
            label: props.filters[filterKey]!.label,
          } as { value: FilterKey; label: string })
      ),
    [props.filters, selectedFilters]
  );

  const onSetSelectedFilters = useCallback(
    function onSetSelectedFilters(newSelectedFilters: { value: FilterKey; label: string }[]) {
      setSelectedFilters(newSelectedFilters.map((filter) => filter.value));
    },
    [setSelectedFilters]
  );

  const handleGroupingChange = useCallback(
    (newSelectedGrouping: GroupingType | null) => {
      setSelectedGrouping(newSelectedGrouping);
      props.onGroupingChange?.(newSelectedGrouping);
    },
    [props, setSelectedGrouping]
  );

  return (
    <div style={props.style} className={classNames(style.FilterBarContainer, props.className)} data-testid="filter-bar">
      <div className={style.leftSection}>
        {props.sidebarCollapseStateAtom && <SidebarToggle sidebarCollapseStateAtom={props.sidebarCollapseStateAtom} />}
        <HiddenFields
          hiddenFieldsListAtom={props.hiddenFieldsListAtom}
          resetHiddenFieldsActionAtom={props.resetHiddenFieldsActionAtom}
          updateHiddenFieldsActionAtom={props.updateHiddenFieldsActionAtom}
          useSegment={props.useSegment}
          filterContextId={props.filterContextId}
        />

        {/* TODO: implement in https://linear.app/dittowords/issue/DIT-7814/project-level-variant-preview */}
        {/* <NavMenuItem Icon={<CallSplitIcon className={style.rotateRight} />} label="Variant" /> */}

        <AddFiltersDropdown
          selectedOptions={selectedFilterOptions}
          setSelectedOptions={onSetSelectedFilters}
          options={filterOptions}
        />

        {statusFilters && (
          <StatusFilterDropdown
            autoFocus
            value={statusFilters}
            onChange={setStatusFilters}
            onRemoveFilter={() => setStatusFilters(null)}
          />
        )}

        {assigneeFilters && (
          <AssigneeFilterDropdown
            autoFocus
            selectedUsers={assigneeFilters}
            setSelectedUsers={setAssigneeFilters}
            usersAtom={props.usersAtom}
            onRemoveFilter={() => setAssigneeFilters(null)}
          />
        )}

        {/*
          NOTE: Temporary for S1
          {variantFilters && <span>Variant</span>}
        */}

        {pageFilters && (
          <PageFilterDropdown
            autoFocus
            pagesAtom={props.pagesAtom}
            selectedPages={pageFilters}
            setSelectedPages={setPageFilters}
            onRemoveFilter={() => setPageFilters(null)}
          />
        )}

        {apiIDFilters && <span>Developer ID</span>}
        {tagsFilters && (
          <TagsFilterDropdown
            autoFocus
            tagsAtom={props.tagsAtom}
            selectedTags={tagsFilters}
            setSelectedTags={setTagsFilters}
            onRemoveFilter={() => setTagsFilters(null)}
          />
        )}

        {selectedFilters.length > 0 && (
          <Button size="micro" level="subtle" onClick={() => setSelectedFilters([])}>
            Clear filters
          </Button>
        )}

        {props.selectedGroupingAtom && (
          <AddGroupingsDropdown selectedGrouping={selectedGrouping} setSelectedGrouping={handleGroupingChange} />
        )}

        {selectedGrouping && (
          <Button size="micro" level="subtle" onClick={() => setSelectedGrouping(null)}>
            Clear grouping
          </Button>
        )}
      </div>
      <div className={style.rightSection}>
        <FilterBarSearch searchAtom={props.searchAtom} placeholder={props.searchPlaceholder} />
        {props.designPreviewToggledAtom && <SeparatorLine color="var(--border-secondary)" height={20} />}
        {props.designPreviewToggledAtom && (
          <DesignPreviews
            designPreviewToggledAtom={props.designPreviewToggledAtom}
            onDesignPreviewToggledChange={props.onDesignPreviewToggledChange}
          />
        )}
      </div>
    </div>
  );
}

function SidebarToggle(props: { sidebarCollapseStateAtom: PrimitiveAtom<CollapseState> }) {
  const [sidebarCollapseState, setSidebarCollapseState] = useAtom(props.sidebarCollapseStateAtom);
  const thumbnailAreaRef = useRef<HTMLDivElement>(null);
  const thumbnailWrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(
    function calculateThumbnailAreaWidth() {
      if (thumbnailAreaRef.current && thumbnailWrapperRef.current) {
        const width = thumbnailAreaRef.current.getBoundingClientRect().width;
        thumbnailWrapperRef.current.style.setProperty("--thumbnail-area-width", `${width}px`);
      }
    },
    [sidebarCollapseState]
  );

  const getThumbnailState = useCallback(function thumbnailState(sidebarState: CollapseState): CollapseState {
    if (sidebarState === "closed") return "open";
    else if (sidebarState === "open") return "closed";
    else return "unset";
  }, []);

  const toggleSidebarCollapse = useCallback(
    function toggleSidebarCollapse() {
      if (sidebarCollapseState === "closed") {
        setSidebarCollapseState("open");
      } else {
        setSidebarCollapseState("closed");
      }
    },
    [setSidebarCollapseState, sidebarCollapseState]
  );

  return (
    <div ref={thumbnailWrapperRef} data-state={getThumbnailState(sidebarCollapseState)}>
      <div ref={thumbnailAreaRef} className={style.thumbnailButtonArea}>
        <Button type="icon" level="subtle" onClick={toggleSidebarCollapse}>
          <ThumbnailBarIcon />
        </Button>
        <SeparatorLine color="var(--border-secondary)" height={20} />
      </div>
    </div>
  );
}

interface IHiddenFieldsProps {
  filterContextId: string;
  useSegment: () => {
    track: (args: unknown) => void;
    debouncedTrack: (args: unknown, debounceTime: number) => void;
  };
  hiddenFieldsListAtom: Atom<IHiddenField[] | null>;
  resetHiddenFieldsActionAtom: WritableAtom<null, [id: string], void>;
  updateHiddenFieldsActionAtom: WritableAtom<
    null,
    [id: string, updatedFields: { value: string; label: string; isHidden: boolean }[]],
    void
  >;
}

function HiddenFields(props: IHiddenFieldsProps) {
  const { debouncedTrack } = props.useSegment();
  const hiddenFieldsList = useAtomValue(props.hiddenFieldsListAtom);
  const resetHiddenFields = useSetAtom(props.resetHiddenFieldsActionAtom);
  const updateHiddenFields = useSetAtom(props.updateHiddenFieldsActionAtom);

  const setHiddenFields = useCallback(
    function setHiddenFields(newSelectedOptions: { value: string; label: string }[]) {
      const selectedValuesSet = new Set(newSelectedOptions.map((option) => option.value));

      const newHiddenFields =
        hiddenFieldsList?.map((field) => {
          if (selectedValuesSet.has(field.value)) {
            return {
              ...field,
              isHidden: true,
            };
          } else {
            return {
              ...field,
              isHidden: false,
            };
          }
        }) ?? [];

      updateHiddenFields(props.filterContextId, newHiddenFields);

      if (newHiddenFields.length > 0) {
        debouncedTrack(
          {
            event: SegmentEvents.FIELDS_HIDDEN,
            properties: {
              fields: newHiddenFields.filter((f) => f.isHidden).map((f) => f.label),
              contextId: props.filterContextId,
            },
          },
          5000
        );
      }
    },
    [debouncedTrack, hiddenFieldsList, props.filterContextId, updateHiddenFields]
  );

  const onResetHiddenFields = useCallback(
    function onResetHiddenFields() {
      resetHiddenFields(props.filterContextId);
    },
    [props.filterContextId, resetHiddenFields]
  );

  if (hiddenFieldsList === null) return;
  return (
    <HideFieldsDropdown
      hiddenFieldsList={hiddenFieldsList}
      setSelectedOptions={setHiddenFields}
      removeFilter={onResetHiddenFields}
    />
  );
}

interface DesignPreviewsProps {
  designPreviewToggledAtom: PrimitiveAtom<boolean>;
  onDesignPreviewToggledChange?: (designPreviewToggled: boolean) => void;
}

function DesignPreviews(props: DesignPreviewsProps) {
  const [designPreviewToggled, setDesignPreviewToggled] = useAtom(props.designPreviewToggledAtom);

  return (
    <div className={style.designPreviewToggle}>
      <Toggle
        pressed={designPreviewToggled}
        onPressedChange={(newDesignPreviewToggled) => {
          setDesignPreviewToggled(newDesignPreviewToggled);
          props.onDesignPreviewToggledChange?.(newDesignPreviewToggled);
        }}
        leadingIcon={<BrokenImageOutlinedIcon />}
        iconSize="xs"
      >
        <Text size="small" color="primary">
          Design preview
        </Text>
      </Toggle>
    </div>
  );
}

FilterBar.Fallback = function FilterBarFallback() {
  return (
    <div className={style.FilterBarContainer}>
      <Skeleton height={30} />
    </div>
  );
};

export default FilterBar;
