import useSegment from "@/hooks/useSegment";
import { projectIdAtom } from "@/stores/Project";
import { shouldSendFireCreatedProjectEventAtom } from "@/stores/User";
import { SHOW_PROJECT_CREATED_TOUR, SHOW_PROJECT_LOADED_TOUR } from "@shared/segment-event-names";
import { useAtom } from "jotai";
import "jotai-devtools/styles.css";
import { Suspense, useEffect, useRef } from "react";
import FilterBar from "./components/FilterBar";
import DialogueModalContainer from "./components/Modals/DialogueModalContainer";
import NavBar from "./components/NavBar";
import ProjectContent from "./components/ProjectContent";
import { WebsocketsHandler } from "./components/WebsocketsHandler";
import style from "./index.module.css";

function ProjectPage() {
  const [projectId, setProjectId] = useAtom(projectIdAtom);
  const segment = useSegment();
  const [shouldSendFireCreatedProjectEvent] = useAtom(shouldSendFireCreatedProjectEventAtom);

  // Make sure we only fire a segment event once for every page mount!
  const onLoadSegmentEventFired = useRef(false);

  useEffect(
    function fireSegmentEventOnPageLoad() {
      if (projectId && !onLoadSegmentEventFired.current) {
        segment.track({
          event: shouldSendFireCreatedProjectEvent ? SHOW_PROJECT_CREATED_TOUR : SHOW_PROJECT_LOADED_TOUR,
          properties: {
            version: "NS",
          },
        });

        onLoadSegmentEventFired.current = true;
      }
    },
    [projectId, shouldSendFireCreatedProjectEvent, segment]
  );

  if (!projectId) return null;
  return (
    <div className={style.wrapper}>
      <WebsocketsHandler projectId={projectId} />
      <NavBar />
      <Suspense fallback={<FilterBar.Fallback />}>
        <FilterBar />
      </Suspense>
      <ProjectContent />
      <DialogueModalContainer />
    </div>
  );
}

export default ProjectPage;
