import { useWorkspace } from "@/store/workspaceContext";
import prepareRichTextNodes from "@shared/common/richText/prepareRichTextNodes";
import ObjectId from "bson-objectid";
import { Selection } from "prosemirror-state";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { TextItem } from "../../../state/types";
import { BLOCK_NAME } from "./nodes/DraftBlockNode";
import { Block, EditorJSON, Paragraph } from "./types";

// this type isn't exported, so we have to do a workaround to get a reference to it
export type ProsemirrorNode = ReturnType<Selection["$to"]["node"]>;

export const getBlockNodeHasContent = (blockNode: ProsemirrorNode): boolean => {
  // there are multiple paragraphs
  let paragraphCount = 0;
  blockNode.content.forEach(() => paragraphCount++);
  if (paragraphCount > 1) return true;

  // there is a single paragraph with text in it
  const paragraph = blockNode.content.child(0);
  return !!paragraph.textContent;
};

export const getEditorHasContent = (json: EditorJSON): boolean => {
  const { content: draftBlocks } = json;

  // there is more than one block
  if (draftBlocks.length > 1) {
    return true;
  }

  // there is one block with multiple paragraphs
  const paragraphs = draftBlocks[0].content;
  if (paragraphs.length > 1) {
    return true;
  }

  // there is one block with one paragraph with content
  const textNodes = paragraphs[0].content;
  if (textNodes?.some(({ text }) => Boolean(text))) {
    return true;
  }

  return false;
};

export const getEmptyBlock = (): Block => ({
  type: BLOCK_NAME,
  attrs: {
    apiId: "",
    textItemId: new ObjectId().toString(),
    commentThreads: [],
    isReadOnly: false,
  },
  content: [
    {
      type: "paragraph",
      content: [],
    },
  ],
});

export const getEmptyEditorJson = (): EditorJSON => ({
  type: "doc",
  content: [getEmptyBlock()],
});

export const textItemsToEditorJson = (
  textItems: TextItem[],
  isReadOnly: boolean,
  richTextEnabled: Boolean
): EditorJSON => {
  if (!textItems.length) {
    return getEmptyEditorJson();
  }

  return {
    type: "doc",
    content: textItems.map((textItem) => {
      const attrs = {
        apiId: textItem.apiID || "",
        textItemId: textItem._id.toString(),
        commentThreads: [...textItem.comment_threads],
        isReadOnly,
      };

      return {
        type: BLOCK_NAME,
        attrs,
        content:
          richTextEnabled && textItem?.rich_text?.content
            ? (prepareRichTextNodes(textItem.rich_text).content as Paragraph[])
            : textItem.text.split("\n").map((textItemLine) => {
                const paragraph: Paragraph = {
                  type: "paragraph",
                  content: [],
                };

                if (textItemLine.trim()) {
                  paragraph.content.push({
                    type: "text",
                    text: textItemLine,
                  });
                }

                return paragraph;
              }),
      };
    }),
  };
};

export const useEditorJsonToTextItems = (developerModeEnabled: boolean) => {
  const { id: projectId } = useParams<{ id: string }>();

  // Need to store the workspaceId in a ref because `useEditor` creates a
  // closure around the initial workspaceContext value - `workspaceId` will
  // sometimes be null if this trick isn't used.
  const workspaceContext = useWorkspace();
  const workspaceIdRef = useRef<string>("");
  useEffect(() => {
    const workspaceId = workspaceContext?.workspaceInfo?._id || null;
    if (workspaceId) {
      workspaceIdRef.current = workspaceId;
    }
  }, [workspaceContext]);

  return (json: EditorJSON): TextItem[] => {
    if (!projectId) {
      throw new Error("Project id couldn't be found in route params");
    }

    if (!workspaceIdRef.current) {
      throw new Error("Workspace wasn't initialized before getting editor JSON");
    }

    const workspaceId = workspaceIdRef.current;

    const textItems: TextItem[] = [];

    for (let b = 0; b < json.content.length; b++) {
      const draftBlock = json.content[b];

      let text = "";

      for (let p = 0; p < draftBlock.content.length; p++) {
        const paragraph = draftBlock.content[p];

        if (p !== 0) {
          text += "\n";
        }

        if (paragraph.content) {
          for (let t = 0; t < paragraph.content.length; t++) {
            const textNode = paragraph.content[t];
            text += textNode.text;
          }
        }
      }

      // Generate a new textItemId if the value doesn't exist
      // This can occur when you enter `cmnd + a + delete` then start typing
      const textItemId = draftBlock.attrs.textItemId || new ObjectId().toString();
      const commentThreads = draftBlock.attrs.commentThreads || [];

      // if a value for `apiId` doesn't exist (i.e. this is a new text item), it needs to be generated on the backend at the
      // time that linking is enabled or at the time the text item is created since workspace dev ID configs ultimately dictate
      // how the API ID should appear (and deduping necessitates access to all text items in the workspace)
      const apiId = draftBlock.attrs.apiId || null;

      const textItem: TextItem = {
        _id: textItemId,
        assignee: null,
        text,
        rich_text: {
          type: "doc",
          content: json.content[b].content,
        },
        workspace_ID: workspaceId,
        doc_ID: projectId!,
        status: "NONE",
        tags: [],
        apiID: apiId,
        variables: [],
        plurals: [],
        notes: null,
        in_graveyard: false,
        graveyard_apiID: null,
        has_conflict: false,
        ws_comp: null,
        comment_threads: commentThreads,
        variants: [],
        integrations: {},
        updatedAt: new Date(),
        lastSync: null,
        text_last_modified_at: new Date(),
        characterLimit: null,
      };

      textItems.push(textItem);
    }

    return textItems;
  };
};
