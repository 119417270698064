import { routes } from "@/defs";
import { selectedFiltersAtomFamily } from "@/stores/LibraryFiltering";
import {
  COMMENT_THREAD_SELECTION_PARAM,
  DETAILS_PANEL_PARAM,
  SELECTED_LIBRARY_COMPONENT_IDS_KEY,
} from "@/stores/Location";
import { normalizeMoveAction } from "@/utils/normalizeMoveAction";
import { GroupingType } from "@ds/molecules/AddGroupingsDropdown";
import { ALL_COMPONENTS } from "@ds/molecules/LibraryComponentFolderFilterDropdown";
import { DetailsPanelProps } from "@ds/organisms/VariantsPanel";
import client from "@shared/frontend/http/httpClient";
import { createEmptyRichText } from "@shared/frontend/richText/plainTextToRichText";
import { extractVariableMetadataFromRichText, serializeTipTapRichText } from "@shared/frontend/richText/serializer";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import atomWithURLStorage from "@shared/frontend/stores/atomWithURLStorage";
import { getPriorSelections } from "@shared/frontend/stores/selection";
import { REFRESH_SILENTLY } from "@shared/frontend/stores/symbols";
import { showToastActionAtom, Toast } from "@shared/frontend/stores/Toast";
import { SoonAtom } from "@shared/frontend/stores/types";
import { isDiffRichText } from "@shared/lib/text";
import { IDittoProject } from "@shared/types/DittoProject";
import { IMoveLibraryComponentsAction } from "@shared/types/http/LibraryComponent";
import { IObjectId } from "@shared/types/lib";
import { ILibraryComponent } from "@shared/types/LibraryComponent";
import { ILibraryComponentFolder } from "@shared/types/LibraryComponentFolder";
import {
  IFigmaV2Instance,
  ITextItem,
  ITextItemVariable,
  ITextItemVariantUpdate,
  ITipTapRichText,
} from "@shared/types/TextItem";
import { AddVariantData, AddVariantUpdateType, IVariant } from "@shared/types/Variant";
import { isNonEmptyArray } from "@shared/utils/array";
import logger from "@shared/utils/logger";
import { Virtualizer } from "@tanstack/react-virtual";
import ObjectID from "bson-objectid";
import { Atom, atom, PrimitiveAtom, SetStateAction, Setter, WritableAtom } from "jotai";
import { derive, soon, soonAll } from "jotai-derive";
import { atomFamily, unwrap } from "jotai/utils";
import { AtomFamily } from "jotai/vanilla/utils/atomFamily";
import { DragStartEvent } from "react-aria";
import { generateKeyBetween } from "../../util/fractionalIndexing";
import {
  libraryComponentFolderFamilyAtom,
  libraryComponentFoldersListAtom,
  refreshLibraryComponentFoldersListAtom,
} from "./ComponentFolders";
import {
  handleLibraryComponentsMovedActionAtom,
  libraryComponentFamilyAtom,
  updateLibraryComponentActionAtom,
} from "./Components";
import { locationAtom, searchAtom } from "./Location";
import { discardChangesModalActionAtom, modalAtom } from "./Modals";
import { selectedCommentAtom } from "./ProjectSelection";
import { textItemFamilyAtom } from "./TextItem";
import { deferredVariantsAtom, projectsAtom, variantsAtom } from "./Workspace";

/**
 * Controls the folder id that is selected in the create component modal in the library.
 * Needs to be global so that it can be updated each time..
 * 1. the user navigates to a new folder
 * 2. the user selects a new folder in the dropdown
 */
const _libraryCreateComponentModalSelectedFolderIdAtom = atom<string | undefined>(undefined);
type LibraryCreateComponentModalSelectedFolderId = string | null | Promise<string | null>;
export const libraryCreateComponentModalSelectedFolderIdAtom = atom(
  (get) => {
    // return explicitly set value for the create modal if it exists
    const libraryCreateComponentModalSelectedFolderId = get(_libraryCreateComponentModalSelectedFolderIdAtom);
    if (libraryCreateComponentModalSelectedFolderId) return libraryCreateComponentModalSelectedFolderId;

    // otherwise, default to the currently selected library folder
    return soon(
      get(selectedLibraryFolderIdAtom),
      (selectedLibraryFolderId) => selectedLibraryFolderId ?? ALL_COMPONENTS.value
    );
  },
  async (get, set, value: LibraryCreateComponentModalSelectedFolderId) => {
    set(_libraryCreateComponentModalSelectedFolderIdAtom, (await value) || ALL_COMPONENTS.value);
  }
);

function getLibraryFolderIdFromLocation(pathname?: string) {
  const folderId = pathname?.match(/library\/([\w\d]+)/)?.[1] ?? null;
  if (!folderId) return null;

  return folderId;
}

/**
 * Represents the id of the currently selected library folder.
 * This is used to load the library list in context of the selected folder.
 */
export const selectedLibraryFolderIdAtom = atom(
  (get) => {
    const location = get(locationAtom);

    const folderId = getLibraryFolderIdFromLocation(location.pathname);
    if (!folderId) return null;

    return soon(get(libraryComponentFoldersListAtom), (libraryComponentFolders) => {
      if (!libraryComponentFolders.some((folder) => folder._id === folderId)) {
        return null;
      }
      return folderId;
    });
  },
  async (get, set, folderId: string | undefined | null, componentsToSelect?: string[]) => {
    const location = get(locationAtom);
    const folderIdCurrent = await get(selectedLibraryFolderIdAtom);

    // re-compute library selection state based on the new folder id;
    // de-select components which don't exist in the folder we're navigating to
    //
    // it's possible for `selectedLibraryFolderAtom` to be set when the app mounts,
    // so we need to re-compute the selection state based on the new folder id
    // rather than just always de-selecting all components each time this setter is called
    //
    // If componentsToSelect is passed in, begin with that value, otherwise use the current library selection
    let selectedComponentIds: string[];
    if (componentsToSelect) {
      selectedComponentIds = componentsToSelect;
    } else {
      const librarySelection = await get(librarySelectedItemsAtom);
      selectedComponentIds = librarySelection.type === "component" ? librarySelection.ids : [];
    }

    if (selectedComponentIds.length) {
      const folderItems = await get(libraryListAtomFamily(folderId ?? undefined));
      const folderComponentsSet = new Set(folderItems.filter((i) => i.type === "component").map((i) => i._id));
      selectedComponentIds = selectedComponentIds.filter((id) => folderComponentsSet.has(id));
    }

    const updateFolderId = (folderId: string | null) => {
      // by including an update to the search params according to the newly (de)selected components,
      // we can avoid a small issue where the URL briefly flashes between multiple states
      let searchParams = atomWithURLStorage.getSearchParamsFromValueUpdate(
        SELECTED_LIBRARY_COMPONENT_IDS_KEY,
        location,
        selectedComponentIds.length ? selectedComponentIds : null
      );

      // always reset the panel selection
      searchParams = atomWithURLStorage.getSearchParamsFromValueUpdate(DETAILS_PANEL_PARAM, location, null);

      // updating the location atom will cause `selectedLibraryFolderIdAtom` to properly re-compute
      set(
        locationAtom,
        {
          ...location,
          pathname: routes.nonNavRoutes.library.getPath(folderId),
          searchParams,
        },
        // if the folder id isn't changing, replace the history state rather than adding a new one
        // (avoids the browser back button needing to be clicked multiple times to navigate back)
        { replace: (folderId ?? null) === folderIdCurrent }
      );

      // update the library selection atom will cause selected components to properly re-compute
      // NOTE: this will internally update the `locationAtom` as well, which is why we're pre-computing
      // changes to the search params above to avoid multiple visible updates to the page location
      set(selectLibraryComponentsActionAtom, selectedComponentIds);

      // each time the folder id changes, we want to update the default value of the selected folder in the create component modal
      set(libraryCreateComponentModalSelectedFolderIdAtom, folderId ?? ALL_COMPONENTS.value);
    };

    if (!folderId) {
      updateFolderId(null);
      return;
    }

    return soon(get(libraryComponentFoldersListAtom), async (libraryComponentFolders) => {
      const folderExists = libraryComponentFolders.some((folder) => folder._id === folderId);
      updateFolderId(folderExists ? folderId : null);
    });
  }
);

/**
 * Returns true if the folder id derived from the url is invalid.
 */
export const isInvalidFolderIdAtom = atom((get) => {
  return soon(get(selectedLibraryFolderIdAtom), (folderId) => {
    const location = get(locationAtom);
    const folderIdFromPathname = getLibraryFolderIdFromLocation(location.pathname);
    return folderId !== folderIdFromPathname;
  });
});

const getFolderIdFromPathname = (pathname: string) => {
  return pathname.match(/\/library\/([\w\d]+)/)?.[1] ?? null;
};

// required to keep the selected folder id in sync with the url in the case
// where the back button is clicked rather than the `selectedLibraryFolderIdAtom` setter
// being called directly
selectedLibraryFolderIdAtom.onMount = function handleBrowserBackButton(set) {
  const listener = (_: PopStateEvent) => {
    const folderId = getFolderIdFromPathname(window.location.pathname);
    set(folderId);
  };
  window.addEventListener("popstate", listener);
  return () => window.removeEventListener("popstate", listener);
};

/**
 * Switches to the given folder, by folderId, if it exists.
 * If the folder no longer exists, will show a toast message and stay in the current folder.
 *
 * If selectedComponentIds is passed in, selects those components (if they are present in the new folder).
 * Otherwise, will maintain the current selection, deselecting any components not present in the new folder.
 *
 * This is meant to be used when the user is already somewhere in the library
 */
export const navigateToLibraryFolderActionAtom = atom(
  null,
  async (get, set, folderId: string | null, componentsToSelect?: string[]) => {
    if (folderId !== null) {
      const folders = await get(libraryComponentFoldersListAtom);
      const folderExists = folders.some((folder) => folder._id === folderId);
      if (!folderExists) {
        set(showToastActionAtom, { message: "That folder was deleted" });
        return;
      }
    }

    set(discardChangesModalActionAtom, {
      onConfirm: () => {
        set(selectedLibraryFolderIdAtom, folderId, componentsToSelect);
      },
      ignoreInlineChanges: false,
      activeElement: document.activeElement,
      isLibraryModal: true,
    });
  }
);

/**
 * Navigates to the component with the given id by:
 * 1. If it's not in the current folder, switches to its folder
 * 2. Selects the component
 *
 * This is meant to be used when the user is already somewhere in the library
 */
export const navigateToLibraryComponentActionAtom = atom(null, async (get, set, componentId: string) => {
  let component: ILibraryComponent | null = null;
  try {
    component = await get(libraryComponentFamilyAtom(componentId));
    if (!component) {
      throw new Error("Component not found");
    }
  } catch (err) {
    set(showToastActionAtom, { message: "That component was deleted" });
    return;
  }

  const currentFolder = await get(selectedLibraryFolderIdAtom);
  if (component.folderId === currentFolder) {
    // The component is in the current folder, just select it and return
    set(selectLibraryComponentsActionAtom, [componentId]);
  } else {
    // Navigate to the folder, then select the component
    set(navigateToLibraryFolderActionAtom, component.folderId, [componentId]);
  }
});

export type LibraryListItem =
  | {
      type: "component";
      _id: string;
      sortKey: string;
    }
  | {
      type: "componentFolder";
      _id: string;
    };

/**
 * Composed of the items to render in the library list. This is the structure of the items in the library.
 * NOTE: this is loaded in the context of the currently selected folder id.
 * NOTE 2: this family can be preloaded by calling `preloadLibraryListAtomFamilyActionAtom`
 */
export const libraryListAtomFamily = atomFamily((folderId?: IObjectId) => {
  const { valueAtom: familyAtom, refreshAtom } = asyncMutableDerivedAtom({
    loadData: async (get) => {
      const statusFilters = get(selectedFiltersAtomFamily("status"));
      const assigneeFilters = get(selectedFiltersAtomFamily("assignee"));
      const tagFilters = get(selectedFiltersAtomFamily("tags"));
      const search = get(searchAtom);
      try {
        const result = await client.library.getLibraryStructure({
          folderId,
          statuses: statusFilters ?? undefined,
          assignees: assigneeFilters ?? undefined,
          tags: tagFilters ?? undefined,
          search: search ?? undefined,
        });
        return result;
      } catch (e) {
        logger.error("Error loading library list", { context: { folderId } }, e);
        // TODO: Render an error component here.
        return [];
      }
    },
  });

  familyAtom.debugLabel = `LibraryList Atom Family (${folderId})`;

  const refreshableFamilyAtom = atom(
    (get) => get(familyAtom),
    (_, set, arg: LibraryListItem[] | typeof REFRESH_SILENTLY) => {
      if (arg === REFRESH_SILENTLY) {
        set(refreshAtom, arg);
        return;
      }

      set(familyAtom, arg);
    }
  );

  return refreshableFamilyAtom;
});

/**
 * Atom family that returns the # of component children a folder has.
 */
export const folderComponentChildrenCountAtomFamily = atomFamily((folderId: string) => {
  return atom((get) => {
    return soon(
      get(libraryListAtomFamily(folderId)),
      (libraryList) => libraryList.filter((item) => item.type === "component").length
    );
  });
});

/**
 * Atom family that returns the TOTAL # of child components a folder has.
 */
export const totalCountsForFolderAtomFamily = atomFamily((folderId: string | undefined) => {
  const { valueAtom } = asyncMutableDerivedAtom({
    loadData: async (get) => {
      const result = await client.libraryComponent.getTotalCounts({
        folderId,
      });

      return {
        componentCount: result.componentCount,
        folderCount: result.folderCount,
      };
    },
  });

  return valueAtom;
});

/**
 * Atom family that returns the # of child folders a folder has.
 */
export const folderChildFoldersCountAtomFamily = atomFamily((folderId: string) => {
  return atom((get) => {
    return soon(
      get(libraryListAtomFamily(folderId)),
      (libraryList) => libraryList.filter((item) => item.type === "componentFolder").length
    );
  });
});

/**
 * Preloads the library list for all component folders loaded in the library. Safe to be called multiple times;
 * since it's calling `get` on atom families instead of making network requests directly, it should be pretty cheap.
 *
 * TODO: in the future when libraries start to get very large, consider optimizing this, since right now
 * it will fire one request per library component folder in the workspace.
 * Potential optimization paths:
 *   1. could reduce number of requests by updating `libraryListAtomFamily` to be a `batchedAsyncAtomFamily`
 *   2. could reduce number of requests by only preloading folders that are children of the currently selected folder
 *   3. could continue preloading folders, but not preload component information by removing references to `libraryComponentFamilyAtom`
 *   4. by removing this atom, you could axe the preload altogether; folder component information will
 *   be loaded on demand
 */
export const preloadLibraryListAtomFamilyActionAtom = atom(null, async (get) => {
  const libraryComponentFoldersList = await get(libraryComponentFoldersListAtom);

  const preloadForFolderId = async (folderId: string | undefined) => {
    // this preloads the library structure for the folder:
    // if you remove this, you'll get page-level loading UI each time you navigate to
    // a new folder for the first time since refreshing
    const items = await get(libraryListAtomFamily(folderId));
    const totalCounts = await get(totalCountsForFolderAtomFamily(folderId));
    // this preloads the data for each component in the folder
    // if you remove this, you'll get component-level loading UI each time you navigate to
    // a new folder for the first time since refreshing
    return items.map((item) => item.type === "component" && get(libraryComponentFamilyAtom(item._id)));
  };

  const preloadForFolderIds = async (folderIds: (string | undefined)[]) => {
    const items = await Promise.all(folderIds.map(preloadForFolderId));
    return items.flat();
  };

  preloadForFolderId(undefined);
  preloadForFolderIds(libraryComponentFoldersList.map((folder) => folder._id));
});

/**
 * Represents the virtualizer for the library list items. Provides a way to programmatically
 * interact with the virtualized list.
 */
export const libraryItemsVirtualizerAtom = atom<Record<string, Virtualizer<Element, Element>>>({});

type ILibraryComponentItem = {
  type: "component";
  _id: string;
  sortKey: string;
  tag?: string;
};

type ILibraryHeaderItem = {
  type: "header";
  _id: string;
  label: string;
};

type ILibraryTagHeaderItem = {
  type: "tag-header";
  _id: string;
  label: string;
  count: number;
};

type ILibrarySeparatorItem = {
  type: "separator";
  _id: string;
};

export type LibraryItem = ILibraryComponentItem | ILibraryHeaderItem | ILibraryTagHeaderItem | ILibrarySeparatorItem;

/**
 * Represents the items to render in the library list. This is the structure of the items in the library.
 */
export const libraryItemsAtom = atom((get) => {
  return soon(get(selectedLibraryFolderIdAtom), (folderId) =>
    soon(get(libraryListAtomFamily(folderId ?? undefined)), (libraryItems) => {
      return libraryItems.filter((item) => item.type === "component");
    })
  );
});

export const libraryFolderItemsAtom = atom((get) => {
  return soon(get(selectedLibraryFolderIdAtom), (folderId) =>
    soon(get(libraryListAtomFamily(folderId ?? undefined)), (libraryItems) => {
      return libraryItems.filter((item) => item.type === "componentFolder");
    })
  );
});

/**
 * Atom family that returns the # of components and folders in a given folder.
 */
export const libraryFolderCountAtomFamily = atomFamily((folderId: string) => {
  return atom((get) => {
    return soon(get(libraryListAtomFamily(folderId ?? undefined)), (libraryItems) => {
      const componentCount = libraryItems.filter((item) => item.type === "component").length;
      const folderCount = libraryItems.filter((item) => item.type === "componentFolder").length;
      return {
        componentCount,
        folderCount,
      };
    });
  });
});

/**
 * The name of the group that contains all components that are not tagged.
 */
export const UNTAGGED_GROUP_NAME = "untagged";

/**
 * Returns a map of tags to library components.
 */
export const libraryComponentsGroupedByTagAtom: Atom<
  Record<string, ILibraryComponent[]> | Promise<Record<string, ILibraryComponent[]>>
> = atom((get) =>
  soon(get(selectedLibraryFolderIdAtom), (folderId) =>
    soon(get(libraryListAtomFamily(folderId ?? undefined)), (libraryItems) => {
      const componentValues = libraryItems
        .filter((item) => item.type === "component")
        .map((item) => get(libraryComponentFamilyAtom(item._id)));
      if (!isNonEmptyArray(componentValues)) return {};

      const components = soonAll(componentValues);

      return soon(components, (components) => {
        const tagMap: Record<string, ILibraryComponent[]> = {};
        for (const component of components) {
          if (component.tags.length === 0) {
            tagMap[UNTAGGED_GROUP_NAME] = [...(tagMap[UNTAGGED_GROUP_NAME] || []), component];
          } else {
            // tags *should* always be unique on a component, but just in case, dedupe here.
            const componentTags = Array.from(new Set(component.tags));
            for (const tag of componentTags) {
              tagMap[tag] ??= [];
              tagMap[tag].push(component);
            }
          }
        }
        return tagMap;
      });
    })
  )
);

/**
 * Flattens the library items grouped by tag into a single list, with tag headers interspersed.
 * e.g. [
 *   { type: "tag-header", _id: "tag1", label: "tag1" },
 *   { type: "component", _id: "abc123", sortKey: "abc123", tag: "tag1" },
 *   { type: "component", _id: "abc124", sortKey: "abc124", tag: "tag1" },
 *   { type: "tag-header", _id: "tag2", label: "tag2" },
 *   { type: "component", _id: "abc125", sortKey: "abc125", tag: "tag2" },
 * ]
 */
export const libraryItemsWithTagHeadersAtom = derive(
  [libraryComponentsGroupedByTagAtom],
  (libraryItemsGroupedByTag) => {
    const entries = Object.entries(libraryItemsGroupedByTag);
    return entries
      .sort(([tagA], [tagB]) => tagA.localeCompare(tagB))
      .flatMap(([tag, components], index) => [
        { type: "tag-header", _id: tag, label: tag, count: components.length } as const,
        ...components.map(
          (component) => ({ type: "component", _id: component._id, sortKey: component.sortKey, tag } as const)
        ),
        ...(index !== entries.length - 1 ? [{ type: "separator", _id: "separator-" + tag } as const] : []),
      ]);
  }
);
libraryItemsWithTagHeadersAtom.debugLabel = "LibraryItemsWithTagHeadersAtom";

/**
 * The count of the items in the library list.
 */
export const libraryItemsCountAtom = derive([libraryItemsAtom], (libraryItems) => libraryItems.length);
libraryItemsCountAtom.debugLabel = "LibraryItemsCountAtom";

/**
 * The count of folder items in the current folder.
 */
export const libraryFolderItemsCountAtom = derive(
  [libraryFolderItemsAtom],
  (libraryFolderItems) => libraryFolderItems.length
);
libraryFolderItemsCountAtom.debugLabel = "LibraryFolderItemsCountAtom";

/**
 * Represents the virtualizer for the library nav items. Provides a way to programmatically
 * interact with the virtualized list.
 */
export const libraryNavItemsVirtualizerAtom = atom<Record<string, Virtualizer<Element, Element>>>({});

/**
 * Represents the items to render in the library nav. This is the structure of the items in the library including components and folders.
 */
export const libraryNavItemsAtom = atom((get) => {
  return soon(get(selectedLibraryFolderIdAtom), (folderId) => {
    return soon(get(libraryListAtomFamily(folderId ?? undefined)), (libraryItems) => {
      return libraryItems;
    });
  });
});

/**
 * Represents the name of the currently selected library folder.
 */
export const libraryCurrentFolderNameAtom = atom((get) => {
  return soon(get(selectedLibraryFolderIdAtom), (folderId) => {
    if (!folderId) return null;
    return soon(get(libraryComponentFolderFamilyAtom(folderId)), (folder) => folder.name);
  });
});

/**
 * Represents the parent id of the currently selected library folder.
 */
export const libraryCurrentFolderParentIdAtom = atom((get) => {
  return soon(get(selectedLibraryFolderIdAtom), (folderId) => {
    if (!folderId) return null;
    return soon(get(libraryComponentFolderFamilyAtom(folderId)), (folder) => folder.parentId);
  });
});

const _libraryCreateComponentModalIsOpenAtom = atom(false);

/**
 * Controls whether the library create component modal is currently showing
 */
export const libraryCreateComponentModalIsOpenAtom = atom(
  (get) => get(_libraryCreateComponentModalIsOpenAtom),
  (get, set, shouldDisplayModal: boolean) => {
    if (shouldDisplayModal) {
      set(discardChangesModalActionAtom, {
        ignoreInlineChanges: false,
        onConfirm: () => {
          set(_libraryCreateComponentModalIsOpenAtom, shouldDisplayModal);
        },
        activeElement: document.activeElement as HTMLElement,
        isLibraryModal: true,
      });
    } else {
      set(_libraryCreateComponentModalIsOpenAtom, shouldDisplayModal);
    }
  }
);

const _libraryCreateFolderModalIsOpenAtom = atom(false);

/**
 * Controls whether the library create folder modal is currently showing
 */
export const libraryCreateFolderModalIsOpenAtom = atom(
  (get) => get(_libraryCreateFolderModalIsOpenAtom),
  (get, set, shouldDisplayModal: boolean) => {
    if (shouldDisplayModal) {
      set(discardChangesModalActionAtom, {
        ignoreInlineChanges: false,
        onConfirm: () => {
          set(_libraryCreateFolderModalIsOpenAtom, shouldDisplayModal);
        },
        activeElement: document.activeElement as HTMLElement,
        isLibraryModal: true,
      });
    } else {
      set(_libraryCreateFolderModalIsOpenAtom, shouldDisplayModal);
    }
  }
);

/**
 * Controls the currently selected grouping in the library.
 */
export const selectedGroupingAtom = atomWithURLStorage("grouping", locationAtom, {
  isString: true,
}) as WritableAtom<GroupingType | null, [GroupingType | null], void>;

// MARK: - Selection

export type LibrarySelection =
  | {
      type: "none";
    }
  | {
      type: "component";
      ids: string[];
    };

export const componentIsSelectedAtomFamily = atomFamily((id: string) =>
  atom((get) => soon(get(selectedComponentIdsAtom), (data) => data.includes(id)))
);

const MAX_ITERATIONS = 100;
const DEFAULT_ITERATIONS_DELAY = 200;
function recursivelyScrollToItemInVirtualizer(set: Setter, id: string, loop: number = 0) {
  let result = set(scrollToComponentIdActionAtom, id);
  if (result[0] === false || result[1] === false) {
    if (loop < MAX_ITERATIONS)
      setTimeout(() => recursivelyScrollToItemInVirtualizer(set, id, ++loop), DEFAULT_ITERATIONS_DELAY);
    else
      logger.error(
        "Failed to scroll to item in virtualizer",
        { context: { id } },
        new Error("Failed to load virtual list!")
      );
  } else {
    logger.debug(`Scrolled to item in virtualizer after ${loop} iterations`, { context: { id, loop } });
  }
}

// MARK: - Drag Selection

export const libraryDraggedSelectionAtom = atom<{
  _id: string;
  type: "component" | "folder";
  origin: "left-sidebar" | "main-list";
} | null>(null);

/**
 * Special atom type for use only in this file.
 * Stores the current selection state for the project.
 * These selection states are mutually exclusive -- we can have text items selected, or blocks selected, but not both.
 * Any other kind of selection logic should be enforced here as well.
 */
function librarySelectionAtom(): WritableAtom<
  LibrarySelection | Promise<LibrarySelection>,
  [SetStateAction<LibrarySelection>],
  void
> {
  const componentIdsAtom = atomWithURLStorage(SELECTED_LIBRARY_COMPONENT_IDS_KEY, locationAtom, {
    onMount: (get, set, componentIdString) => {
      if (!componentIdString) return;
      const componentIds = componentIdString.split(",");
      if (componentIds.length > 0) {
        recursivelyScrollToItemInVirtualizer(set, componentIds[0]);
      }
    },
    replace: true,
  });
  componentIdsAtom.debugLabel = "componentIdsAtom (librarySelectionAtom)";
  // atomWithURLStorage will return an array of strings if the URL param is present, and null if it's not
  // we should probably allow it to just store a string, but for now the code in this atom takes
  // care of string <> array conversion

  const selectionAtom = atom(
    (get) => {
      return soon(get(libraryNavItemsAtom), (libraryNavItems) => {
        const componentIds = get(componentIdsAtom);
        /**
         * Check that the items actually exist in the current folder
         */
        const filteredComponentIds = componentIds?.filter((id) => libraryNavItems.some((item) => item._id === id));

        // It's possible for the URL to have an empty value for the selection URL keys. This means the atom returns a value
        // of an empty array. As of the time of this comment, we treat this case as if the selection is empty. If this ever
        // changes, we should make sure that every consumer of `selectedLibraryComponentIdsAtom` knows they might
        // receive an empty array.
        if (filteredComponentIds && filteredComponentIds.length > 0) {
          return { type: "component", ids: filteredComponentIds } as LibrarySelection;
        } else {
          return { type: "none" } as LibrarySelection;
        }
      });
    },
    async (get, set, newSelection: LibrarySelection) => {
      set(selectedCommentAtom, null);

      if (newSelection.type === "none") {
        set(componentIdsAtom, null);
        return;
      }

      if (newSelection.type === "component") {
        set(componentIdsAtom, newSelection.ids);
        set(scrollToComponentIdActionAtom, newSelection.ids[0]);
        return;
      }

      logger.error("Invalid selection type", { context: { newSelection } });
      return;

      // // Either immediately change selection or show a discard changes confirmation modal
      // set(discardChangesModalActionAtom, {
      //   onConfirm: _handleSelectionChange,
      //   ignoreInlineChanges: false,
      //   activeElement: document.activeElement,
      // });
    }
  );

  return selectionAtom;
}

/**
 * Scrolls to the component with the given id in the virtualized list.
 */
export const scrollToComponentIdActionAtom = atom(null, (get, set, componentId: string) => {
  const contentVirtualizers = get(libraryItemsVirtualizerAtom);
  const navListVirtualizers = get(libraryNavItemsVirtualizerAtom);

  let mainNavResult = scrollToItemInVirtualizer({
    itemId: componentId,
    virtualizers: contentVirtualizers,
  });

  let leftNavResult = scrollToItemInVirtualizer({
    itemId: componentId,
    virtualizers: navListVirtualizers,
  });

  return [mainNavResult, leftNavResult];
});

/**
 * Scrolls to the item with the given id in the virtualizer.
 */
function scrollToItemInVirtualizer(props: {
  itemId: string;
  scrollOptions?: ScrollToOptions;
  virtualizers: Record<string, Virtualizer<Element, Element>>;
}) {
  if (Object.values(props.virtualizers).length === 0) {
    return false;
  }
  for (const virtualizer of Object.values(props.virtualizers)) {
    let item = virtualizer.measurementsCache.find((item) => item.key.toString().includes(props.itemId));
    if (item) {
      // Check if item is already in view
      const itemRect = virtualizer.measurementsCache[item.index];
      const scrollRect = virtualizer.scrollElement?.getBoundingClientRect();
      const scrollTop = virtualizer.scrollElement?.scrollTop ?? 0;
      const scrollBottom = scrollTop + (virtualizer.scrollElement?.clientHeight ?? 0);

      if (scrollRect && itemRect) {
        const startInView = itemRect.end >= scrollTop;
        const endInView = itemRect.start <= scrollBottom;
        const isInView = startInView && endInView;

        if (!isInView) {
          virtualizer.scrollToIndex(item.index, { align: "center" });
        }
      }

      // virtualizer.scrollToIndex(item.index, { align: "center" });
      return true;
    }
  }
  logger.warn("Could not find item to scroll to", { context: { itemId: props.itemId } });
}

/**
 * Tracks the current selection state for the library.
 * With limited exception, do not directly update the atom, please use one of these actions:
 * - selectLibraryComponentsActionAtom
 * - deselectLibraryComponentsActionAtom
 * - clearLibrarySelectionActionAtom
 */
export const librarySelectedItemsAtom = librarySelectionAtom();

/**
 * A deduped list of the currently selected component ids.
 */
export const selectedComponentIdsAtom = atom((get) => {
  return soon(get(librarySelectedItemsAtom), (selection) => {
    if (selection.type === "component") {
      return Array.from(new Set(selection.ids));
    } else {
      return [];
    }
  });
});
selectedComponentIdsAtom.debugLabel = "selectedComponentIdsAtom";

/**
 * If exactly one component is selected, returns its id.
 * Otherwise, returns null.
 */
export const derivedOnlySelectedComponentIdAtom = derive([selectedComponentIdsAtom], (selectedIds) => {
  if (selectedIds.length !== 1) return null;
  return selectedIds[0];
});
derivedOnlySelectedComponentIdAtom.debugLabel = "derivedOnlySelectedComponentIdAtom";

export const hasExactlyOneComponentSelectedAtom = derive([selectedComponentIdsAtom], (ids) => ids.length === 1);
hasExactlyOneComponentSelectedAtom.debugLabel = "hasExactlyOneComponentSelectedAtom";

export const unwrappedSelectedComponentIdsAtom = unwrap(selectedComponentIdsAtom, (prev) => prev ?? []);

const _selectedComponentsAtom = atom((get) => {
  return soon(get(selectedComponentIdsAtom), (selectedComponentIds) => {
    const valuesArray = selectedComponentIds.map((id) => get(libraryComponentFamilyAtom(id)));
    if (!isNonEmptyArray(valuesArray)) return [] as ILibraryComponent[];
    const components = soonAll(valuesArray);
    return components;
  });
});

export const derivedSelectedComponentsAtom = derive([_selectedComponentsAtom], (items) => items);
derivedSelectedComponentsAtom.debugLabel = "derivedSelectedComponentsAtom";

/**
 * If exactly one component is selected, returns the full data for that component.
 * Otherwise, returns null.
 */
export const derivedOnlySelectedComponentAtom = derive([_selectedComponentsAtom], (selectedItems) => {
  if (selectedItems.length !== 1) return null;
  return selectedItems[0];
});
derivedOnlySelectedComponentAtom.debugLabel = "derivedOnlySelectedComponentAtom";

/**
 * Returns the variants that exist on the only selected text item, with their variant names from the derived workspace variants atom.
 */
export const derivedOnlySelectedComponentVariantsAtom = derive(
  [derivedOnlySelectedComponentAtom, deferredVariantsAtom],
  (selectedComponent, wsVariants) => {
    if (!selectedComponent) return [];
    return selectedComponent.variants.map((componentVariant) => ({
      ...componentVariant,
      name: wsVariants.find((wsVariant) => wsVariant._id === componentVariant.variantId)?.name ?? "",
    }));
  }
);

export const libraryDetailsPanelPropsAtom = atom<DetailsPanelProps>({});

export const libraryDetailsPanelResetFormStateSignalAtom = atom(false);

/**
 * This atom is used to determine which items are draggable for a given component item.
 *
 * If the component primarily being dragged isn't selected, then only that component
 * is draggable. Otherwise, the entire set of selected components is draggable.
 */
export const draggableItemsForComponentItemAtom = atomFamily((id: string) =>
  atom((get) => {
    const selectedComponentIds = get(unwrappedSelectedComponentIdsAtom);
    const isSelected = selectedComponentIds.includes(id);
    const draggableIds = isSelected ? selectedComponentIds : [id];

    return draggableIds.map((item) => ({
      "ditto/componentItem": item,
      "plain/text": item,
    }));
  })
);

/**
 * Represents the virtualizer for the library component instances panel. Provides a way to programmatically
 * interact with the virtualized list.
 */
export const libraryInstancesPanelVirtualizerAtom = atom<Record<string, Virtualizer<Element, Element>>>({});

interface IFigmaFrameWithInstances {
  type: "frame";
  frameId: string;
  frameName: string;
  nodeIds: string[];
  textItemIds: Set<string>;
}

interface IFigmaPageWithInstances {
  type: "page";
  pageId: string;
  pageName: string;
  nodeIds: string[];
}

export interface ILibraryInstancesPanelListItem {
  project: IDittoProject;
  textItems: ITextItem[];
  framesAndPages: (IFigmaFrameWithInstances | IFigmaPageWithInstances | { type: "empty"; id: string })[];
  collapsedAtom: PrimitiveAtom<boolean>;
}

// Helper function to extract info about where a text item instance resides in the linked Figma files
function getTopLevelNode(
  project: IDittoProject,
  instance: IFigmaV2Instance
): {
  type: "frame" | "page";
  nodeId: string;
  name: string;
} | null {
  const frameName = project.integrations.figma.topLevelFrames?.[instance.figmaTopLevelFrameId]?.name;
  if (frameName) {
    return { type: "frame", nodeId: instance.figmaTopLevelFrameId, name: frameName } as const;
  } else {
    const pageName = project.integrations.figma.pages?.[instance.figmaPageId]?.name;
    if (pageName) {
      return { type: "page", nodeId: instance.figmaPageId, name: pageName } as const;
    }
  }
  return null;
}

/**
 * Atom family that returns the following metadata for a given library component:
 * - The number of instances (linked text items) of the component
 * - The number of projects that contain instances of the component
 */
export const libraryInstanceCountsFamilyAtom: AtomFamily<
  string,
  SoonAtom<{ instanceCount: number; projectCount: number } | null>
> = atomFamily((componentId: string) => {
  return atom((get) => {
    const component = get(libraryComponentFamilyAtom(componentId));

    return soon(component, (component) => {
      if (!component) return null;

      const componentInstancesPromises = component.instances.map((id) => get(textItemFamilyAtom(id)));
      if (!isNonEmptyArray(componentInstancesPromises)) return { instanceCount: 0, projectCount: 0 };
      const componentTextItemPromises = soonAll(componentInstancesPromises);

      return soon(componentTextItemPromises, (componentTextItems) => {
        const uniqueProjects = new Set();
        componentTextItems.forEach((textItem) => {
          if (textItem.doc_ID) {
            uniqueProjects.add(textItem.doc_ID);
          }
        });

        return { instanceCount: componentTextItems.length, projectCount: uniqueProjects.size };
      });
    });
  });
});

/**
 * Atom family that returns a list of text item data for the given component.
 * Each list is grouped by DittoProject, and contains a list of text items and the associated top-level
 * Figma nodes where the text items have linked instances.
 */
export const libraryInstancesDataFamilyAtom: AtomFamily<
  string,
  Atom<Promise<ILibraryInstancesPanelListItem[]> | ILibraryInstancesPanelListItem[]>
> = atomFamily((componentId: string) => {
  return atom((get) => {
    const component = get(libraryComponentFamilyAtom(componentId));

    return soon(component, (component) => {
      if (!component) return [];
      const componentInstancesPromises = component.instances.map((id) => get(textItemFamilyAtom(id)));

      if (!isNonEmptyArray(componentInstancesPromises)) return [];

      const componentTextItemPromises = soonAll(componentInstancesPromises);
      return soon(componentTextItemPromises, (componentTextItems) => {
        return soon(get(projectsAtom), (projects) => {
          // Map over all text items associated with the component and group them by project,
          // extracting info about where the text item instances reside in the linked Figma files.
          const recordOfItems = componentTextItems.reduce<
            Record<
              string,
              {
                project: IDittoProject;
                textItems: ITextItem[];
                frames: Record<string, IFigmaFrameWithInstances>;
                pages: Record<string, IFigmaPageWithInstances>;
                collapsedAtom: PrimitiveAtom<boolean>;
              }
            >
          >((map, textItemInstance) => {
            if (!textItemInstance.doc_ID) return map;
            const project = projects.find((project) => project._id === textItemInstance.doc_ID);
            if (!project) return map;

            // Init the entry for this project in the map (if it doesn't exist yet)
            map[textItemInstance.doc_ID] ??= {
              project,
              frames: {},
              pages: {},
              textItems: [],
              collapsedAtom: atom(true),
            };

            // Add the text item to the project's list of text items
            map[textItemInstance.doc_ID].textItems.push(textItemInstance);

            for (const figmaNodeInstance of textItemInstance.integrations.figmaV2?.instances ?? []) {
              // Extract info about where the text item instances reside in the linked Figma files
              const topLevelNode = getTopLevelNode(project, figmaNodeInstance);
              if (!topLevelNode) continue;

              // Add node info to the appropriate project-level map entry, initializing the entry if necessary
              if (topLevelNode.type === "frame") {
                const frameData = (map[textItemInstance.doc_ID].frames[figmaNodeInstance.figmaTopLevelFrameId] ??= {
                  type: "frame",
                  frameId: figmaNodeInstance.figmaTopLevelFrameId,
                  frameName: topLevelNode.name,
                  nodeIds: [],
                  textItemIds: new Set<string>(),
                });

                frameData.nodeIds.push(figmaNodeInstance.figmaNodeId);
                frameData.textItemIds.add(textItemInstance._id);
              } else {
                const pageData = (map[textItemInstance.doc_ID].pages[figmaNodeInstance.figmaPageId] ??= {
                  type: "page",
                  pageId: figmaNodeInstance.figmaPageId,
                  pageName: topLevelNode.name,
                  nodeIds: [],
                });

                pageData.nodeIds.push(figmaNodeInstance.figmaNodeId);
              }
            }

            return map;
          }, {});

          return Object.values(recordOfItems)
            .sort((a, b) => a.project.name.localeCompare(b.project.name))
            .map((project) => {
              const framesAndPages = [
                ...Object.values(project.pages).sort((a, b) => a.pageName.localeCompare(b.pageName)),
                ...Object.values(project.frames).sort((a, b) => a.frameName.localeCompare(b.frameName)),
              ];
              if (framesAndPages.length === 0) {
                return {
                  ...project,
                  framesAndPages: [
                    {
                      type: "empty",
                      id: project.project._id,
                    },
                  ],
                };
              }

              return {
                ...project,
                framesAndPages,
              };
            });
        });
      });
    });
  });
});

export const nullableInstanceDataFamilyAtom = atomFamily((componentId: string | null) => {
  return atom((get) => {
    if (!componentId) return null;
    return get(libraryInstancesDataFamilyAtom(componentId));
  });
});

export const instanceDataForSelectedComponentAtom = atom((get) => {
  const selectedComponent = get(derivedOnlySelectedComponentAtom);
  return soon(selectedComponent, (selectedComponent) => {
    if (!selectedComponent) return [];
    return get(libraryInstancesDataFamilyAtom(selectedComponent._id));
  });
});

export const selectedLibraryInstanceCountAtom = derive(
  [instanceDataForSelectedComponentAtom],
  (libraryInstancesPanelListItems) =>
    libraryInstancesPanelListItems.reduce((acc, projectRow) => acc + projectRow.textItems.length, 0)
);
selectedLibraryInstanceCountAtom.debugLabel = "selectedLibraryInstanceCountAtom";

// MARK: - Actions

/**
 * Clears the current library selection
 */
export const clearLibrarySelectionActionAtom = atom(null, (get, set) => {
  // If a modal is already showing, don't overwrite it. Global click handling will cause a modal conflict.
  if (get(modalAtom)) return;

  set(discardChangesModalActionAtom, {
    ignoreInlineChanges: false,
    onConfirm: () => {
      set(librarySelectedItemsAtom, { type: "none" });
    },
    activeElement: document.activeElement,
    isLibraryModal: true,
  });
});

/**
 * Updates the library selection to the provided ids.
 * Clears selection if an empty list is provided.
 *
 * Use this instead of setting the librarySelectedItemsAtom directly.
 */
export const selectLibraryComponentsActionAtom = atom(null, (get, set, ids: string[]) => {
  set(discardChangesModalActionAtom, {
    ignoreInlineChanges: false,
    onConfirm: () => {
      if (ids.length > 0) {
        set(librarySelectedItemsAtom, { type: "component", ids });
      } else {
        set(clearLibrarySelectionActionAtom);
      }
    },
    activeElement: document.activeElement,
    isLibraryModal: true,
  });
});

/**
 * Removes the provided ids from the current library selection.
 * Clears the selection if the ids provided match the entire current selection.
 *
 * Use this instead of setting the librarySelectedItemsAtom directly.
 */
export const deselectLibraryComponentsActionAtom = atom(null, async (get, set, ids: string[]) => {
  if (ids.length === 0) {
    return;
  }

  const previousSelection = await get(librarySelectedItemsAtom);
  if (previousSelection.type === "none") {
    return;
  }

  const updatedSelectedIds = previousSelection.ids.filter((id) => !ids.includes(id));
  set(selectLibraryComponentsActionAtom, updatedSelectedIds);
});

export const handleComponentClickActionAtom = atom(
  null,
  async (
    get,
    set,
    props: {
      event: React.MouseEvent<HTMLElement, MouseEvent> | DragStartEvent;
      componentId: IObjectId;
      skipInlineEditing: boolean;
    }
  ) => {
    const isSelected = get(componentIsSelectedAtomFamily(props.componentId));
    // TODO: handle inline editing
    const isInlineEditing = false;
    // Note: Not using selectedComponentIdsAtom here, because that has been deduped and this is the one case
    // in which we want to preserve the order and potential duplication of selected items
    const selection = await get(librarySelectedItemsAtom);
    const selectedComponentIds = selection.type === "component" ? selection.ids : [];
    if (props.event && "shiftKey" in props.event && props.event.shiftKey) {
      // We need the ordered list of all text items to determine the range of elements to select.
      const components = await get(libraryItemsAtom);
      // If we press SHIFT+click, select the range of elements between the most recently selected element and the current one.
      const mostRecentlySelectedIndex = components.findIndex(
        (item) => item._id === selectedComponentIds[selectedComponentIds.length - 1]
      );
      // If the last selected element is not found (should never happen), select the current element.
      if (mostRecentlySelectedIndex === -1) {
        // TODO: handle inline editing
        set(selectLibraryComponentsActionAtom, [props.componentId]);
        return;
      }
      const currentIndex = components.findIndex((item) => item._id === props.componentId);
      const startIndex = Math.min(mostRecentlySelectedIndex, currentIndex);
      const endIndex = Math.max(mostRecentlySelectedIndex, currentIndex);
      const alreadyPresentSelections = getPriorSelections(
        selectedComponentIds,
        components,
        currentIndex,
        mostRecentlySelectedIndex,
        startIndex,
        endIndex
      );
      // Create the new selections array and ensure the most recently selected element is preserved at the end.
      const newSelections = [
        ...alreadyPresentSelections,
        ...components.slice(startIndex, mostRecentlySelectedIndex).map((item) => item._id),
        ...components
          .slice(mostRecentlySelectedIndex, endIndex + 1)
          .map((item) => item._id)
          .reverse(),
      ];
      // TODO: handle inline editing
      set(selectLibraryComponentsActionAtom, newSelections);
    } else if (props.event && "metaKey" in props.event && props.event.metaKey) {
      // If we press CMD+click, toggle selection of the element, without affecting other selections.
      // TODO: Handle inline editing. See the project for an example of this.
      if (isSelected) {
        set(deselectLibraryComponentsActionAtom, [props.componentId]);
      } else {
        // Place the text item twice in the selections array to properly handle both pivot and pointer state (relevant for arrow-shift selection)
        set(selectLibraryComponentsActionAtom, [props.componentId, ...selectedComponentIds, props.componentId]);
      }
    } else {
      // If we plain-select a selected element, turn on inline editing and remove other selections.
      if (isSelected) {
        if (selectedComponentIds.length > 1) {
          // other things were selected, so we should just select this one
          set(selectLibraryComponentsActionAtom, [props.componentId]);
        } else {
          // nothing else was selected, so we should just inline edit unless we are skipping inline editing
          if (props.skipInlineEditing) {
            set(clearLibrarySelectionActionAtom);
          } else {
            // TODO; set inline editing
          }
        }
      } else if (!isSelected) {
        if (isInlineEditing) {
          // TODO: Handle inline editing. See the project for an example of this.
        } else {
          set(selectLibraryComponentsActionAtom, [props.componentId]);
        }
        return;
      }
    }
  }
);

export const fetchAndInsertNewLibraryComponentActionAtom = atom(
  null,
  async (get, set, props: { componentId: string }) => {
    const component = await get(libraryComponentFamilyAtom(props.componentId));

    const libraryStructureNode = libraryListAtomFamily(component.folderId || undefined);

    // Only insert the component if it's not already in the library structure
    if ((await get(libraryStructureNode)).some((item) => item._id === props.componentId)) {
      return;
    }

    set(libraryStructureNode, [
      ...(await get(libraryStructureNode)),
      { type: "component", _id: props.componentId, sortKey: component.sortKey },
    ]);
  }
);

/**
 * Creates a new library component from the library page.
 */
export const createLibraryComponentActionAtom = atom(
  null,
  async (
    get,
    set,
    props: {
      folderId: IObjectId | null;
      name: string;
      richText: ITipTapRichText;
      text: string;
      workspaceId: IObjectId;
      variables: ITextItemVariable[];
    }
  ) => {
    const optimisticComponent: ILibraryComponent = {
      _id: ObjectID().toHexString(),
      name: props.name,
      rich_text: props.richText,
      text: props.text,
      folderId: props.folderId || null,
      status: "NONE",
      instances: [],
      commentThreads: [],
      variables: props.variables,
      variants: [],
      plurals: [],
      tags: [],
      assignee: null,
      notes: null,
      apiId: crypto.randomUUID(),
      sortKey: "ZZZZZZZZZZZZZZZZZZZZZZZZ",
      workspaceId: props.workspaceId,
    };

    // Optimistically update the component family atom

    libraryComponentFamilyAtom(optimisticComponent._id, optimisticComponent);
    const libraryStructureNode = libraryListAtomFamily(optimisticComponent.folderId || undefined);
    set(libraryStructureNode, [
      ...(await get(libraryStructureNode)),
      { type: "component", _id: optimisticComponent._id, sortKey: optimisticComponent.sortKey },
    ]);

    try {
      const result = await client.libraryComponent.createLibraryComponent({
        _id: optimisticComponent._id,
        name: optimisticComponent.name,
        richText: optimisticComponent.rich_text,
        folderId: optimisticComponent.folderId || null,
        variables: optimisticComponent.variables,
      });
      set(updateLibraryComponentActionAtom, { _id: optimisticComponent._id, update: result.newComponent });

      // Show created component toast message
      let toast: Toast = {
        message: "",
      };
      let folderName = "All components";

      // Select the newly created component if it's in the current folder
      if (optimisticComponent.folderId === (get(selectedLibraryFolderIdAtom) || null)) {
        set(selectLibraryComponentsActionAtom, [result.newComponent._id]);
      } else {
        toast.action = "View";
        toast.onClickAction = () => {
          if (result.newComponent.folderId) {
            set(selectedLibraryFolderIdAtom, result.newComponent.folderId);
            set(locationAtom, (previous) => ({
              ...previous,
              pathname: routes.nonNavRoutes.library.getPath(result.newComponent.folderId),
            }));
          } else {
            set(selectedLibraryFolderIdAtom, undefined);
            set(locationAtom, (previous) => ({
              ...previous,
              pathname: routes.nonNavRoutes.library.getPath(),
            }));
          }
          set(selectLibraryComponentsActionAtom, [result.newComponent._id]);
        };
      }

      if (result.newComponent.folderId) {
        folderName = (await get(libraryComponentFolderFamilyAtom(result.newComponent.folderId))).name;
      }

      toast.message = `Created component "${result.newComponent.name}" in "${folderName}"`;
      set(showToastActionAtom, toast);
    } catch (error) {
      logger.error("Failed to create library component", { context: { optimisticComponent } }, error);
      libraryComponentFamilyAtom.remove(optimisticComponent._id);
      set(
        libraryStructureNode,
        (await get(libraryStructureNode)).filter((item) => item._id !== optimisticComponent._id)
      );
      set(showToastActionAtom, {
        message: `Something went wrong while creating the component. Please try again later.`,
      });
    }
  }
);

export const moveLibraryComponentsFrontendActionAtom = atom(
  null,
  async (
    get,
    set,
    args: {
      actions: IMoveLibraryComponentsAction[];
      options?: {
        /**
         * Whether the move is coming from a websocket event.
         * If true, we will show a toast message.
         */
        isWebsocketEvent?: boolean;
      };
    }
  ) => {
    const normalizedActions: Array<IMoveLibraryComponentsAction | null> = await Promise.all(
      args.actions.map(async (action) => {
        // Action with no components to move is a no-op
        if (action.componentIds.length === 0) return null;

        // Skip normalization if we're moving the components into a different folder
        const component = await get(libraryComponentFamilyAtom(action.componentIds[0]));
        if (component?.folderId !== action.folderId) {
          return action;
        }

        // items in the group we're moving into (folder or root if no folderId specified)
        const itemsInCollection = await get(libraryListAtomFamily(action.folderId || undefined));
        const itemIdsInCollection = itemsInCollection.map((item) => item._id);

        // sort selected items to determine whether we're moving a component (or group starting with that component) onto itself
        const itemsInSelection = itemsInCollection.filter(
          (item): item is typeof item & { type: "component" } =>
            item.type === "component" && action.componentIds.includes(item._id)
        );
        const itemIdsInSelectionSorted = itemsInSelection
          .sort((a, b) => a.sortKey.localeCompare(b.sortKey))
          .map((item) => item._id);

        // Moving a component (or group starting with that component) onto itself is a no-op
        if (itemIdsInSelectionSorted[0] === action.before || itemIdsInSelectionSorted[0] === action.after) {
          return null;
        }

        const direction = action.before ? "before" : "after";

        const normalized = normalizeMoveAction({
          itemIds: action.componentIds,
          referenceItemId: action.before ?? action.after ?? undefined,
          direction,
          itemIdsInCollection,
          allItemIds: itemIdsInCollection,
        });

        return {
          ...action,
          componentIds: normalized.itemIds,
          before: normalized.direction === "before" ? normalized.referenceItemId : undefined,
          after: normalized.direction === "after" ? normalized.referenceItemId : undefined,
        };
      })
    );

    const normalizedValidActions = normalizedActions.filter((action) => action !== null);
    if (normalizedValidActions.length === 0) return;

    // Show toast if selection includes components that are being moved
    if (args.options?.isWebsocketEvent) {
      // refresh the components silently
      set(handleLibraryComponentsMovedActionAtom, { actions: normalizedValidActions });

      const movedComponentNames = new Set<string>();

      const selection = await get(librarySelectedItemsAtom);

      for (const action of normalizedValidActions) {
        // only care about folder ids that are not the selected folder
        if (action.folderId === get(selectedLibraryFolderIdAtom)) continue;

        // only care if the component is selected
        const selectedComponentIdsMoved: string[] =
          selection.type === "component" ? selection.ids.filter((id) => action.componentIds.includes(id)) : [];

        for (const componentId of selectedComponentIdsMoved) {
          const component = await get(libraryComponentFamilyAtom(componentId));
          movedComponentNames.add(component.name);
        }
      }

      if (movedComponentNames.size > 1) {
        set(showToastActionAtom, {
          message: `${movedComponentNames.size} components were moved`,
        });
      } else if (movedComponentNames.size === 1) {
        set(showToastActionAtom, {
          message: `"${movedComponentNames.values().next().value}" was moved`,
        });
      }
    }

    // handle optimistically moving the components
    for (const action of normalizedValidActions) {
      const referenceComponentId = action.before ?? action.after;

      // if we're moving the component into itself, skip the rest of the loop
      if (action.componentIds.length && action.componentIds[0] === referenceComponentId) continue;

      // if we're inserting the components items *after* a reference item, we need to do it in reverse order since we are
      // inserting them one at a time.
      //
      // e.g. if our array is ["a", "b", "c"] and we want to insert ["X", "Y"] after "b",
      // -- we first insert "Y": ["a", "b", "Y", "c"]
      // -- then "X": ["a", "b", "X", "Y", "c"]
      const componentIdsToMove = [...action.componentIds];
      if (action.after) componentIdsToMove.reverse();

      // the location in library structure (e.g. root or folder) we're moving from
      const libraryOrigin = (await get(libraryComponentFamilyAtom(action.componentIds[0]))).folderId;
      // the location in library structure (e.g. root or folder) we're moving to
      const libraryDestination = action.folderId;
      const isMovingCompsToNewLocation = libraryDestination !== undefined && libraryDestination !== libraryOrigin;

      for (const componentId of componentIdsToMove) {
        const componentAtom = libraryComponentFamilyAtom(componentId);
        const component = await get(componentAtom);

        const originItems = [...(await get(libraryListAtomFamily(libraryOrigin ?? undefined)))];
        const destinationItems =
          libraryOrigin === libraryDestination
            ? originItems
            : [...(await get(libraryListAtomFamily(libraryDestination ?? undefined)))];

        // old position of the component being moved
        const oldPositionIdx = originItems.findIndex((item) => item._id === componentId);
        if (oldPositionIdx === -1) {
          throw new Error("Component not found in original library location");
        }

        // remove the component from the origin items
        originItems.splice(oldPositionIdx, 1);
        set(libraryListAtomFamily(libraryOrigin ?? undefined), [...originItems]);

        // the component we're dropping our selection on, either after or before
        const referenceComponentIdx = destinationItems.findIndex((item) => item._id === referenceComponentId);
        if (referenceComponentId && referenceComponentIdx === -1) {
          throw new Error("Reference component not found in library destination");
        }

        // new position, end of destination array if no reference position provided
        let positionToInsertIdx = destinationItems.length;

        if (referenceComponentId) {
          if (action.before) positionToInsertIdx = referenceComponentIdx;
          if (action.after) positionToInsertIdx = referenceComponentIdx + 1;
        }

        destinationItems.splice(positionToInsertIdx, 0, {
          _id: componentId,
          type: "component",
          sortKey: component.sortKey,
        });

        set(libraryListAtomFamily(libraryDestination ?? undefined), [...destinationItems]);

        // Case where we're moving component to a different folder (or root)
        if (isMovingCompsToNewLocation) {
          set(componentAtom, (prev) => ({ ...prev, folderId: libraryDestination }));
        }
      }
    }

    return normalizedValidActions;
  }
);

export const reorderLibraryComponentsActionAtom = atom(
  null,
  async (get, set, actions: IMoveLibraryComponentsAction[]) => {
    const normalizedValidActions = await set(moveLibraryComponentsFrontendActionAtom, { actions });

    if (!normalizedValidActions) return;

    try {
      // backend request is responsible for updating the sort keys, make sure to update
      const result = await client.libraryComponent.moveLibraryComponents({
        actions: normalizedValidActions,
      });
      const { idToKeyMap, actionResults } = result;
      for (const entry of Object.entries(idToKeyMap)) {
        const [componentId, sortKey] = entry;
        const componentAtom = libraryComponentFamilyAtom(componentId);
        set(componentAtom, (prev) => ({ ...prev, sortKey }));
      }

      let movedToNewFolder = false;
      for (const actionResult of actionResults) {
        const { destinationFolderId, componentIds } = actionResult;
        if (destinationFolderId === undefined) continue;

        // Check if we've moved to a new folder
        const sourceFolders = await Promise.all(
          componentIds.map(async (id) => (await get(libraryComponentFamilyAtom(id))).folderId)
        );
        if (sourceFolders.some((folderId) => folderId !== destinationFolderId)) {
          movedToNewFolder = true;
        }

        const folderName = destinationFolderId
          ? (await get(libraryComponentFolderFamilyAtom(destinationFolderId))).name
          : "All components";

        if (componentIds.length === 1) {
          const componentName = (await get(libraryComponentFamilyAtom(componentIds[0]))).name;
          set(showToastActionAtom, {
            message: `Moved component "${componentName}" to "${folderName}"`,
          });
        } else {
          set(showToastActionAtom, {
            message: `Moved ${componentIds.length} components to "${folderName}"`,
          });
        }
      }

      // Only clear selection if we moved items to a new folder
      if (movedToNewFolder) {
        set(clearLibrarySelectionActionAtom);
      }
    } catch (error) {
      logger.error("Error moving library components", { context: { normalizedValidActions } }, error);
      throw error;
    }
  }
);

/**
 * Creates a new library component folder from the library page.
 */
export const createLibraryComponentFolderActionAtom = atom(
  null,
  async (get, set, props: { parentId: IObjectId | null; name: string; workspaceId: IObjectId }) => {
    const optimisticComponentFolder: ILibraryComponentFolder = {
      _id: ObjectID().toHexString(),
      name: props.name,
      apiId: crypto.randomUUID(),
      parentId: props.parentId || null,
      workspaceId: props.workspaceId,
      // sort key needs to be fixed after the real folder is created
      sortKey: generateKeyBetween(null, null),
    };

    // Optimistically update the component folder family atom
    libraryComponentFolderFamilyAtom(optimisticComponentFolder._id, optimisticComponentFolder);
    const libraryStructureNode = libraryListAtomFamily(optimisticComponentFolder.parentId || undefined);
    let updatedStructure = [...(await get(libraryStructureNode))];
    const indexOfFirstTextItem = updatedStructure.findIndex((item) => item.type === "component");
    if (indexOfFirstTextItem === -1) {
      updatedStructure.push({ type: "componentFolder", _id: optimisticComponentFolder._id });
    } else {
      updatedStructure.splice(indexOfFirstTextItem, 0, { type: "componentFolder", _id: optimisticComponentFolder._id });
    }
    set(libraryStructureNode, updatedStructure);

    // update child structure node optimistically
    const childStructureNode = libraryListAtomFamily(optimisticComponentFolder._id);
    set(childStructureNode, []);

    try {
      const result = await client.libraryComponentFolder.createLibraryComponentFolder(optimisticComponentFolder);
      set(libraryComponentFolderFamilyAtom(optimisticComponentFolder._id), result.newComponentFolder);
      set(showToastActionAtom, {
        message: `Created folder "${result.newComponentFolder.name}"`,
        action: "View",
        onClickAction: () => {
          set(selectedLibraryFolderIdAtom, result.newComponentFolder._id);
        },
      });
      set(refreshLibraryComponentFoldersListAtom);
    } catch (error) {
      logger.error("Failed to create library component", { context: { optimisticComponentFolder } }, error);
      libraryComponentFolderFamilyAtom.remove(optimisticComponentFolder._id);
      set(
        libraryStructureNode,
        (await get(libraryStructureNode)).filter((item) => item._id !== optimisticComponentFolder._id)
      );
      set(showToastActionAtom, {
        message: `Something went wrong while creating the component folder. Please try again later.`,
      });
    }
  }
);

// MARK: - Details Panel

const LibraryDetailsPanelContexts = ["GENERAL", "EDIT"] as const;
export type LibraryDetailsPanelContext = (typeof LibraryDetailsPanelContexts)[number];

export const libraryDetailsPanelContextAtom = atom((get) => {
  return soon(get(selectedComponentIdsAtom), (selectedComponentIds) => {
    if (selectedComponentIds.length > 0) {
      return "EDIT";
    }

    return "GENERAL";
  });
});

const LibraryDetailsGeneralPanelTabs = ["ACTIVITY", "COMMENTS"] as const;
export type LibraryDetailsGeneralPanelTab = (typeof LibraryDetailsGeneralPanelTabs)[number];

export const libraryDetailsGeneralPanelSelectedTabAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return "ACTIVITY";
    }

    const state = location.searchParams.get(DETAILS_PANEL_PARAM) as LibraryDetailsGeneralPanelTab;

    if (state === null || !LibraryDetailsGeneralPanelTabs.includes(state)) {
      return "ACTIVITY";
    }

    return state;
  },
  (get, set, newPanel: LibraryDetailsGeneralPanelTab) => {
    function handleChangePanel() {
      const location = get(locationAtom);
      const newSearchParams = new URLSearchParams(location.searchParams);

      if (newPanel) {
        newSearchParams.set(DETAILS_PANEL_PARAM, newPanel);
      } else {
        newSearchParams.delete(DETAILS_PANEL_PARAM);
      }

      set(locationAtom, {
        ...location,
        searchParams: newSearchParams,
      });
    }

    set(discardChangesModalActionAtom, {
      onConfirm: handleChangePanel,
      ignoreInlineChanges: true,
      activeElement: document.activeElement,
      isLibraryModal: true,
    });
  }
);

const LibraryDetailsEditPanelTabs = ["EDIT", "INSTANCES", "ACTIVITY", "COMMENTS", "VARIANTS"] as const;
export type LibraryDetailsEditPanelTab = (typeof LibraryDetailsEditPanelTabs)[number];

export const LibraryDetailsEditPanelTabAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return "EDIT";
    }

    const state = location.searchParams.get(DETAILS_PANEL_PARAM) as LibraryDetailsEditPanelTab;

    if (state === null || !LibraryDetailsEditPanelTabs.includes(state)) {
      return "EDIT";
    }

    return state;
  },
  (get, set, newPanel: LibraryDetailsEditPanelTab) => {
    function handleChangePanel() {
      const location = get(locationAtom);
      const newSearchParams = new URLSearchParams(location.searchParams);

      if (newPanel) {
        newSearchParams.set(DETAILS_PANEL_PARAM, newPanel);
      } else {
        newSearchParams.delete(DETAILS_PANEL_PARAM);
      }
      newSearchParams.delete(COMMENT_THREAD_SELECTION_PARAM);

      set(
        locationAtom,
        {
          ...location,
          searchParams: newSearchParams,
        },
        { replace: true }
      );
      set(selectedCommentAtom, null);
    }

    set(discardChangesModalActionAtom, {
      onConfirm: handleChangePanel,
      ignoreInlineChanges: true,
      activeElement: document.activeElement,
      isLibraryModal: true,
    });
  }
);

// MARK: - Details Panel Actions

export const libraryAttachVariantActionAtom = atom(
  null,
  async (
    get,
    set,
    props: { variant: AddVariantData; updateType: AddVariantUpdateType; itemId: string; workspaceId: string }
  ) => {
    // Get the library component and workspace variants for optimistic updates
    const libraryComponentAtom = libraryComponentFamilyAtom(props.itemId);
    const libraryComponent = await get(libraryComponentAtom);
    const originalLibraryComponentVariants = [...libraryComponent.variants];
    const originalWorkspaceVariants = await get(variantsAtom);

    // If we're attaching an existing variant, use its ID
    // Otherwise, we're creating a new variant, so generate an optimistic ID for Jotai and to save to backend
    const variantId = props.variant.variantId ? props.variant.variantId : new ObjectID().toString();

    // If update type is CREATE, we're creating a new variant
    // Optimistically add variant to store of workspace variants
    if (props.updateType === "CREATE") {
      const newVariant: IVariant = {
        name: props.variant.name ?? "",
        _id: variantId,
        workspace_ID: props.workspaceId,
        description: "",
        apiID: "",
        docs: [],
        components: [],
        folder_id: null,
        isSample: false,
      };

      // Update the workspace variants to include the new variant
      set(variantsAtom, [...originalWorkspaceVariants, newVariant]);
    }

    // Extract variable metadata for optimistic update
    const variantVariables = extractVariableMetadataFromRichText(props.variant.rich_text);

    // Update the library component to include the new variant in Jotai
    set(libraryComponentAtom, (prev) => ({
      ...prev,
      variants: [
        // Push new variant to the front of the array
        {
          variantId: variantId,
          text: serializeTipTapRichText(props.variant.rich_text).text,
          rich_text: props.variant.rich_text,
          status: props.variant.status,
          lastSync: null,
          lastSyncRichText: null,
          variables: variantVariables,
          plurals: [],
          text_last_modified_at: new Date(),
        },
        ...prev.variants,
      ],
    }));

    try {
      const result = await client.libraryComponent.updateLibraryComponents({
        updates: [
          {
            libraryComponentIds: [props.itemId],
            richText: props.variant.rich_text,
            status: props.variant.status,
          },
        ],
        ...(props.updateType === "CREATE"
          ? {
              newVariant: {
                name: props.variant.name ?? "",
                variantId,
              },
            }
          : {}),
        ...(props.updateType === "ATTACH" ? { variantId } : {}),
      });

      // Update any library components that were affected
      const updatedLibraryComponents = Object.values(result.updatedLibraryComponents);
      for (const updatedLibraryComponent of updatedLibraryComponents) {
        set(updateLibraryComponentActionAtom, { _id: updatedLibraryComponent._id, update: updatedLibraryComponent });
      }
    } catch (e) {
      set(showToastActionAtom, { message: "Something went wrong attaching the variant to this component" });
      logger.error(
        `Error updating library component with id ${props.itemId}`,
        { context: { workspaceId: props.workspaceId } },
        e
      );

      // revert optimistic update
      set(variantsAtom, originalWorkspaceVariants);
      set(libraryComponentAtom, (prev) => ({ ...prev, variants: originalLibraryComponentVariants }));
    }
  }
);

export const libraryUpdateItemVariantActionAtom = atom(
  null,
  async (get, set, props: { itemId: string; update: ITextItemVariantUpdate; workspaceId: string }) => {
    // Update the library component's variant data in Jotai
    const libraryComponentAtom = libraryComponentFamilyAtom(props.itemId);
    const libraryComponent = await get(libraryComponentAtom);

    // Find the variant index to update (if index not found, attach the variant)
    const variantToUpdateIndex = libraryComponent.variants.findIndex((v) => v.variantId === props.update.variantId);

    // Make sure something actually changed before continuing
    const oldVariant = libraryComponent.variants[variantToUpdateIndex];
    const hasDiff =
      // variant is being added
      !oldVariant ||
      // variant status is being changed
      ("status" in props.update && oldVariant.status !== props.update.status) ||
      // variant rich text is being changed
      ("richText" in props.update && isDiffRichText(oldVariant.rich_text, props.update.richText));

    if (!hasDiff) return;

    if (!hasDiff) return;

    const originalLibraryComponentVariants = [...libraryComponent.variants];
    let libraryComponentVariantsUpdated = [...libraryComponent.variants];

    if (variantToUpdateIndex === -1) {
      // If the variant doesn't exist on the library component, create and push to front
      const newVariant = {
        variantId: props.update.variantId,
        text: props.update.richText ? serializeTipTapRichText(props.update.richText).text : "",
        rich_text: props.update.richText ?? createEmptyRichText(),
        status: props.update.status ?? "NONE",
        lastSync: null,
        lastSyncRichText: null,
        variables: [],
        plurals: [],
        text_last_modified_at: new Date(),
      };
      libraryComponentVariantsUpdated = [newVariant, ...libraryComponentVariantsUpdated];
    } else {
      // Create a deep copy of the variant to update, so we can revert if the update fails
      const updatedLibraryComponentVariant = structuredClone(libraryComponent.variants[variantToUpdateIndex]);
      libraryComponentVariantsUpdated[variantToUpdateIndex] = {
        ...updatedLibraryComponentVariant,
        ...(props.update.richText
          ? {
              rich_text: props.update.richText,
              text: serializeTipTapRichText(props.update.richText).text,
            }
          : {}),
        ...(props.update.status ? { status: props.update.status } : {}),
      };
    }

    set(libraryComponentAtom, (prev) => ({
      ...prev,
      variants: libraryComponentVariantsUpdated,
    }));

    try {
      const result = await client.libraryComponent.updateLibraryComponents({
        updates: [
          {
            libraryComponentIds: [props.itemId],
            ...(props.update.status ? { status: props.update.status } : {}),
            ...(props.update.richText ? { richText: props.update.richText } : {}),
          },
        ],
        variantId: props.update.variantId,
      });

      // Update any library components that were affected
      const updatedLibraryComponents = Object.values(result.updatedLibraryComponents);
      for (const updatedLibraryComponent of updatedLibraryComponents) {
        set(updateLibraryComponentActionAtom, { _id: updatedLibraryComponent._id, update: updatedLibraryComponent });
      }
    } catch (e) {
      set(showToastActionAtom, { message: "Something went wrong updating the variant for this component" });
      logger.error(
        `Error updating library component with id ${props.itemId}`,
        { context: { workspaceId: props.workspaceId } },
        e
      );

      // revert optimistic update
      set(libraryComponentAtom, (prev) => ({ ...prev, variants: originalLibraryComponentVariants }));
    }
  }
);

export const libraryRemoveVariantFromItemsActionAtom = atom(
  null,
  async (get, set, props: { itemIds: string[]; variantId: string; workspaceId: string }) => {
    // Get all the library components and their current variants for optimistic updates and potential rollback
    const libraryComponentAtoms = props.itemIds.map((id) => libraryComponentFamilyAtom(id));
    const libraryComponents = await Promise.all(props.itemIds.map((id) => get(libraryComponentFamilyAtom(id))));
    const originalLibraryComponentVariants = libraryComponents.map((component) => [...component.variants]);

    // Optimistically update each library component by removing the variant
    for (let i = 0; i < props.itemIds.length; i++) {
      const libraryComponentAtom = libraryComponentAtoms[i];
      set(libraryComponentAtom, (prev) => ({
        ...prev,
        variants: prev.variants.filter((variant) => variant.variantId !== props.variantId),
      }));
    }

    try {
      await client.libraryComponent.removeVariantFromLibraryComponents({
        libraryComponentIds: props.itemIds,
        variantId: props.variantId,
      });

      if (props.itemIds.length > 1) {
        set(showToastActionAtom, {
          message: `Removed variant from ${props.itemIds.length} components`,
        });
      }
    } catch (e) {
      set(showToastActionAtom, { message: "Something went wrong removing the variant from the components" });
      logger.error(
        `Error removing variant ${props.variantId} from library components`,
        { context: { workspaceId: props.workspaceId, componentIds: props.itemIds } },
        e
      );

      // Revert optimistic updates
      for (let i = 0; i < props.itemIds.length; i++) {
        const libraryComponentAtom = libraryComponentAtoms[i];
        set(libraryComponentAtom, (prev) => ({
          ...prev,
          variants: originalLibraryComponentVariants[i],
        }));
      }
    }
  }
);

/**
 * Family atom that returns the data of a library component's variant.
 * @param key The unique key for the component - variant pair (in format `{componentId}-{variantId}`).
 * @returns The variant data for the given component - variant pair, including variant name.
 */
export const libraryComponentVariantsFamilyAtom = atomFamily((key: string) => {
  const [componentId, variantId] = key.split("-");
  const componentVariantAtom = derive(
    [libraryComponentFamilyAtom(componentId), deferredVariantsAtom],
    (component, wsVariants) => {
      const variant = component.variants.find((variant) => variant.variantId === variantId);
      if (!variant) return null;

      return {
        ...variant,
        name: wsVariants.find((wsVariant) => wsVariant._id === variant.variantId)?.name ?? "",
        placeholder: component.text,
      };
    }
  );
  componentVariantAtom.debugLabel = `libraryComponentVariantAtom: ${componentId}-${variantId}`;
  return componentVariantAtom;
});
