import useSegment from "@/hooks/useSegment";
import {
  LIBRARY_HIDDEN_FIELDS_ID,
  libraryHiddenFieldsListAtom,
  resetHiddenFieldsActionAtom,
  updateHiddenFieldsActionAtom,
} from "@/stores/HiddenFields";
import { selectedGroupingAtom } from "@/stores/Library";
import { LIBRARY_FILTERS, searchAtom, selectedFiltersAtom, selectedFiltersValuesAtomFamily } from "@/stores/Location";
import { allComponentTagsAtom, deferredUsersAtom } from "@/stores/Workspace";
import { HIDE_JOTAI_DEVTOOLS } from "@/utils/featureFlags";
import { GroupingType } from "@ds/molecules/AddGroupingsDropdown";
import LibraryFilterBar from "@ds/organisms/FilterBar";
import { GROUP_FILTER_ADDED } from "@shared/segment-event-names";
import { atom } from "jotai";
import { DevTools } from "jotai-devtools";
import "jotai-devtools/styles.css";
import { useCallback } from "react";
import DialogueModalContainer from "../ProjectNS/components/Modals/DialogueModalContainer";
import LegacyBanner from "./components/LegacyBanner";
import LibraryContent from "./components/LibraryContent";
import NavBar from "./components/LibraryNavBar";
import style from "./style.module.css";

const _dummyPagesAtom = atom([]);

function Library() {
  const segment = useSegment();

  const handleGroupingChange = useCallback(
    (newSelectedGrouping: GroupingType | null) => {
      if (!newSelectedGrouping) return;

      segment.track({
        event: GROUP_FILTER_ADDED,
        properties: {
          location: "library",
          type: newSelectedGrouping,
          version: "NS",
        },
      });
    },
    [segment]
  );

  return (
    <div className={style.libraryContainer}>
      <LegacyBanner />
      <NavBar />
      <LibraryFilterBar
        filterContextId={LIBRARY_HIDDEN_FIELDS_ID}
        searchAtom={searchAtom}
        useSegment={useSegment}
        hiddenFieldsListAtom={libraryHiddenFieldsListAtom}
        resetHiddenFieldsActionAtom={resetHiddenFieldsActionAtom}
        updateHiddenFieldsActionAtom={updateHiddenFieldsActionAtom}
        filters={LIBRARY_FILTERS}
        selectedFiltersValuesAtomFamily={selectedFiltersValuesAtomFamily}
        selectedFiltersAtom={selectedFiltersAtom}
        usersAtom={deferredUsersAtom}
        tagsAtom={allComponentTagsAtom}
        pagesAtom={_dummyPagesAtom}
        selectedGroupingAtom={selectedGroupingAtom}
        onGroupingChange={handleGroupingChange}
        searchPlaceholder="Find in view..."
      />
      <LibraryContent />
      <DialogueModalContainer />
      {process.env.ENV === "development" && !HIDE_JOTAI_DEVTOOLS && (
        <DevTools
          options={{ shouldShowPrivateAtoms: false, shouldExpandJsonTreeViewInitially: true }}
          position="bottom-right"
        />
      )}
    </div>
  );
}

export default Library;
