import { NS_BETA_URL_PATH_NAME } from "@shared/common/constants";
import { IObjectId } from "@shared/types/lib";
import { LibraryDetailsGeneralPanelTab } from "./stores/Library";
import {
  COMMENT_THREAD_SELECTION_PARAM,
  DETAILS_PANEL_PARAM,
  SELECTED_LIBRARY_COMPONENT_IDS_KEY,
} from "./stores/Location";

export const routes = {
  nonNavRoutes: {
    component: {
      name: "Component",
      path: "/components/:id",
      getPath: (componentId) => `/components/${componentId}`,
    },
    variable: {
      name: "Variables",
      path: "/variables/:id",
    },
    variant: {
      name: "Variants",
      path: "/variants/:id",
    },
    folder: {
      name: "Folder",
      path: "/folders/:id",
      getPath: (folderId: string) => `/folders/${folderId}`,
    },
    project: {
      name: "Projects",
      path: "/projects/:id",
      getPath: (projectId?: string) => {
        if (projectId) return `/projects/${projectId}`;
        return `/projects`;
      },
    },
    projectBeta: {
      name: "Projects",
      path: `/${NS_BETA_URL_PATH_NAME}/:id`,
      getPath: (projectId?: string) => {
        if (projectId) return `/${NS_BETA_URL_PATH_NAME}/${projectId}`;
        return `/${NS_BETA_URL_PATH_NAME}`;
      },
    },
    library: {
      name: "Library",
      path: `/library/:folderId?`,
      getPath: (folderId?: IObjectId | null) => {
        return folderId ? `/library/${folderId}` : `/library`;
      },
      getUrlParts: ({
        folderId,
        selectedComponentIds,
        detailsPanel,
        commentThreadId,
      }: {
        folderId?: IObjectId | null;
        selectedComponentIds?: IObjectId[];
        detailsPanel?: LibraryDetailsGeneralPanelTab;
        commentThreadId?: IObjectId | null;
      } = {}) => {
        const pathname = routes.nonNavRoutes.library.getPath(folderId);
        const searchParams = new URLSearchParams();

        if (selectedComponentIds) {
          searchParams.set(SELECTED_LIBRARY_COMPONENT_IDS_KEY, selectedComponentIds.join(","));
        }
        if (detailsPanel) {
          searchParams.set(DETAILS_PANEL_PARAM, detailsPanel);
        }
        if (commentThreadId) {
          searchParams.set(DETAILS_PANEL_PARAM, "COMMENTS");
          searchParams.set(COMMENT_THREAD_SELECTION_PARAM, commentThreadId);
        }
        return { pathname, searchParams };
      },
    },
    branchProject: {
      name: "Projects",
      path: "/projects/:id/branch/:branchId",
      getPath: (projectId, branchId) => `/projects/${projectId}/branch/${branchId}`,
    },
    projectPage: {
      name: "Projects",
      path: "/projects/:id/page/:pageId",
      getPath: (projectId, pageId) => `/projects/${projectId}/page/${pageId}`,
    },
    projectPageInstance: {
      name: "Projects",
      path: "/projects/:id/page/:pageId/:instanceId",
      getPath: (projectId, pageId, instanceId) => `/projects/${projectId}/page/${pageId}/${instanceId}`,
    },
    dev_paywall: {
      name: "Dev Modal",
      path: "/dev-modal",
    },
    onboarding: {
      name: "Onboarding",
      path: "/onboarding/:onboardingState?/:redirectDocID?",
    },
    doc: {
      name: "Document",
      path: "/doc/:id",
    },
    join_workspace: {
      name: "Join Workspace",
      path: "/workspace/join/:role/:ws_share_id",
    },
    legacy_join_workspace: {
      name: "Join Workspace (Legacy)",
      path: "/workspace/join/:ws_id",
    },
    login: {
      name: "Login",
      path: "/login",
    },
    loginSaml: {
      name: "Login SAML",
      path: "/login/saml",
    },
    loginFigma: {
      name: "Login Figma",
      path: "/figma-authentication",
    },
    custom_login: {
      name: "Custom Login",
      path: "/signin/:company/:redirect_doc_id?",
    },
    planLimit: {
      name: "Plan Limit",
      path: "/plan-limit",
    },
    authCallback: {
      name: "Auth0 Callback",
      path: "/auth-callback",
    },
    logout: {
      name: "Logout",
      path: "/logout",
    },
  },
  links: {
    faq: "https://www.notion.so/Ditto-FAQ-85c2132308b54c44b75e141609f6e39a",
    tutorials: "https://www.dittowords.com/docs/introduction",
    updates: "https://www.notion.so/dittov3/Ditto-Product-Updates-62c2466828e9491e95d659dbd5455e79",
    whatsNew: "https://www.dittowords.com/whats-new",
  },
};

export const TOAST_TYPES = {
  edit_error: "EDIT_ERROR",
  default: "DEFAULT",
};

export const ROLE_OPTIONS = {
  admin: "Admin",
  editor: "Editor",
  commenter: "Commenter",
};

export const ROLE_NAMES = {
  admin: "Admin",
  editor: "Editor",
  commenter: "Commenter",
};
export const APP_URLS = {
  dev: "localhost:3000",
  staging: "staging.dittowords.com",
  prod: "app.dittowords.com",
};

export const SuggestionTabs = {
  attach: "attach-comp",
  create: "create-comp",
};

export const COMPONENT_LIBRARY_PANEL_STATES = {
  // Panel states at the component library level
  library: {
    // Activity tab
    none: "none",
    // Activity tab
    inline_reply: "inline_reply",
    // Suggestions tab
    merge_suggestions: "merge_suggestions",
  },
  // Panel states at the component level
  component: {
    edit: "edit",
    instances: "instances",
    activity: "activity",
    variants: "VARIANTS",
  },
};

export const PANELS = {
  comp_library: {
    ...COMPONENT_LIBRARY_PANEL_STATES.library,
    ...COMPONENT_LIBRARY_PANEL_STATES.component,
  },
  doc: {
    edit: "EDIT",
    inline_reply: "INLINE-REPLY",
    activity: "HIST",
    variants: "VARIANTS",
    suggestions: "COMPONENT_SUGGESTIONS",
  },
};
// Just needs to not have whitespace
// https://making-ditto.slack.com/archives/C0328T320TE/p1679684862369549?thread_ts=1679684240.421789&cid=C0328T320TE
export const VALID_URL = /^\S*$/;

export const IS_FLOAT = /^(\d|\.)+$/;
