import classNames from "classnames";
import React, { ForwardedRef, forwardRef, useCallback, useEffect, useMemo, useRef } from "react";
import { EMPTY_RICH_TEXT } from "../../../../shared/frontend/constants";
import useIsComponentVisible from "../../../../shared/frontend/hooks/useIsComponentVisible";
import { MaybeAtom } from "../../../../shared/frontend/MaybeAtom";
import { ActualComponentStatus, ITipTapRichText } from "../../../../shared/types/TextItem";
import { IUser } from "../../../../shared/types/User";
import Badge from "../../atoms/Badge";
import Text from "../../atoms/Text";
import { VariantBadgeData } from "../../molecules/VariantBadge";
import LibraryComponentNameSpan from "../LibraryComponentNameSpan";
import { RichTextRender } from "../RichTextRender";
import {
  Badges,
  EditableInput,
  HiddenNodesCountBadge,
  InstanceCountBadge,
  TextIcon,
  TextItemComponentState,
  TextItemEditState,
  TextItemLevel,
} from "./index.lib";
import style from "./index.module.css";

export type { TextItemComponentState, TextItemEditState, TextItemLevel } from "./index.lib";

export interface IProps {
  defaultText?: string;
  defaultValue?: ITipTapRichText;

  /**
   * The status of the component.
   */
  status?: ActualComponentStatus;
  variants?: VariantBadgeData[];
  assignee?: Pick<IUser, "name" | "picture">;
  numComments?: number;
  instanceCount?: number;
  hiddenNodesCount?: number;
  tags?: string[];
  notes?: string | null;
  placeholder?: string;
  variantNotPresent?: boolean;
  hideVariantsBadge?: boolean;
  component?: { name: MaybeAtom<string>; folderId?: string | null };
  borderColor?: string;

  canDrag?: boolean;

  state?: TextItemComponentState;
  editState?: TextItemEditState;
  expansion?: "inline" | "block";
  variant?: "default" | "published" | "unmanaged";
  level?: TextItemLevel;
  showTextIcon?: boolean;
  autoFocus?: boolean;
  metadataCanWrap?: boolean;
  highlightedPhrase?: string | null;
  showComponentInstances?: boolean;
  /**
   * Temporary prop to disable autoscrolling when the component is focused.
   * This will be removed once we migrate to the virtualized list.
   */
  disableAutoscroll?: boolean;

  onClickSave?: (value: ITipTapRichText) => Promise<void> | void;
  onClickCancel?: () => Promise<void> | void;
  onEscape?: () => Promise<void> | void;
  onTextChange?: (richText: ITipTapRichText) => void;
  onTextFocus?: React.FocusEventHandler<HTMLDivElement>;
  onTextBlur?: React.FocusEventHandler<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onInstanceCountClick?: () => void | Promise<void>;

  className?: string;
  style?: React.CSSProperties;
  renderDragGhost?: boolean;
}

const TextItemBase = forwardRef(function TextItemBase(
  props: IProps & { Text: React.ReactNode },
  forwardRef: ForwardedRef<HTMLDivElement>
) {
  const { onClick } = props;

  const ref = useRef<HTMLDivElement | null>(null);

  // Merge internal ref with optional external ref
  function setRefs(node: HTMLDivElement | null) {
    ref.current = node;

    if (forwardRef) {
      (forwardRef as React.MutableRefObject<HTMLDivElement | null>).current = node;
    }
  }

  const { isVisibleRef: isFullyVisibleRef } = useIsComponentVisible(ref, 0.99);
  const level = props.level ?? "default";

  useEffect(() => {
    // Don't autoscroll if the prop is set. Scrolling is handled natively by the virtualized list.
    if (props.disableAutoscroll) {
      return;
    }

    // When the component becomes focused, scroll it into view if it's not already visible.
    if (props.state === "focus" && !isFullyVisibleRef.current && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [props.state, isFullyVisibleRef, props.disableAutoscroll]);

  const showComments = (props.numComments ?? 0) > 0;
  const showBadges =
    props.editState !== "editing" &&
    props.level !== "minimal" &&
    props.level !== "essential" &&
    (!!props.variants || showComments || !!props.assignee || !!props.variantNotPresent);
  const showNotes = props.notes && props.notes.length > 0 && props.level !== "essential";
  const showTags = props.tags && props.tags.length > 0 && props.level !== "essential";

  const wrapBadges = props.level === "compact";
  const showInstances = props.instanceCount !== undefined;
  const showHiddenNodesCount = props.hiddenNodesCount !== undefined;

  return (
    <div
      style={props.style}
      className={classNames(
        style.TextItemWrapper,
        {
          [style.inline]: props.expansion === "inline",
          [style.component]: !!props.component,
          [style[`state-${props.state}`]]: props.state,
          [style[`edit-state-${props.editState}`]]: props.editState,
          [style[`variant-${props.variant}`]]: props.variant,
          [style[`level-${props.level}`]]: props.level,
          [style.withTextIcon]: props.showTextIcon,
        },
        props.className
      )}
      data-testid="text-item"
      ref={setRefs}
      onClick={onClick}
    >
      {props.renderDragGhost && <div className={style.dragGhostOverlay} />}
      <div className={style.borderWrapper} style={{ borderColor: props.borderColor }} />
      {props.status && (
        <div
          className={classNames(style.status, {
            [style[`status-${props.status}`]]: props.status,
          })}
        />
      )}
      {props.showTextIcon && <TextIcon />}

      <div className={style.main}>
        <div className={style.textItemVerticalSplit}>
          <div
            className={classNames({
              [style.textRow]: true,
              [style.textRowCompact]: level === "compact" || level === "minimal" || level === "essential",
            })}
          >
            <div className={style.componentNameAndText}>
              {props.component && (
                <MaybeAtom
                  value={props.component.name}
                  fallback={<LibraryComponentNameSpan className={style.componentName}>&nbsp;</LibraryComponentNameSpan>}
                >
                  {(value) => <ComponentName name={value} highlightedPhrase={props.highlightedPhrase} />}
                </MaybeAtom>
              )}
              {props.Text}
            </div>

            {!wrapBadges && showBadges && (
              <Badges
                variantNotPresent={props.variantNotPresent}
                variants={props.variants}
                hideVariantsBadge={props.hideVariantsBadge}
                numComments={props.numComments}
                assignee={props.assignee}
                instanceCount={props.instanceCount}
                level={props.level}
              />
            )}
          </div>

          {showInstances && (
            <InstanceCountBadge
              isComponent={props.showComponentInstances}
              instanceCount={props.instanceCount ?? 0}
              level={props.level}
              onClick={props.onInstanceCountClick}
            />
          )}
          <HiddenNodesCountBadge hiddenNodesCount={props.hiddenNodesCount} />
        </div>
        {wrapBadges && showBadges && (
          <Badges
            variantNotPresent={props.variantNotPresent}
            variants={props.variants}
            numComments={props.numComments}
            assignee={props.assignee}
            instanceCount={props.instanceCount}
            level={props.level}
            hideVariantsBadge={props.hideVariantsBadge}
          />
        )}
        {(showNotes || showTags) && (
          <div className={style.notesArea}>
            {showNotes && (
              <Text size="micro" color="secondary">
                {props.notes}
              </Text>
            )}

            {showTags && (
              <div className={style.tags}>
                {props.tags?.map((tag, i) => (
                  <Badge key={tag + i} type="outline" borderRadius="md" size="xs" className={style.tag}>
                    {tag}
                  </Badge>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
});

// Wrapper around LibraryComponentNameSpan that highlights portions of the component name matching
// a given phrase to highlight.
function ComponentName(props: { name: string; highlightedPhrase?: string | null }) {
  const { name, highlightedPhrase } = props;

  const nameJsx = useMemo(() => {
    if (!highlightedPhrase) {
      return name;
    }

    const regex = new RegExp(`(${highlightedPhrase})`, "gi");
    const parts = name.split(regex);

    return parts.map((part, i) => {
      if (regex.test(part)) {
        return (
          <span key={part + i} className={style.highlight}>
            {part}
          </span>
        );
      } else {
        return <span key={part + i}>{part}</span>;
      }
    });
  }, [highlightedPhrase, name]);

  return <LibraryComponentNameSpan>{nameJsx}</LibraryComponentNameSpan>;
}

export const TextItem = forwardRef(function TextItem(props: IProps, ref: ForwardedRef<HTMLDivElement>) {
  const { onClickSave, onClickCancel, onEscape, onTextBlur, onTextChange, onTextFocus } = props;

  const handleSave = useCallback(
    (richText: ITipTapRichText) => {
      return onClickSave?.(richText);
    },
    [onClickSave]
  );

  const handleCancel = useCallback(
    async function _handleCancel() {
      await onClickCancel?.();
    },
    [onClickCancel]
  );

  return (
    <TextItemBase
      {...props}
      ref={ref}
      Text={
        <EditableInput
          autoFocus={props.autoFocus}
          defaultValue={props.defaultValue ?? EMPTY_RICH_TEXT}
          editState={props.editState || "none"}
          highlightedPhrase={props.highlightedPhrase}
          placeholder={props.placeholder}
          onSave={handleSave}
          onClickCancel={handleCancel}
          onEscape={onEscape}
          onTextChange={onTextChange}
          onFocus={onTextFocus}
          onBlur={onTextBlur}
        />
      }
    />
  );
});

/**
 * A static version of `<TextItem />` that does not mount a text editor and (therefore) does not allow editing.
 *
 * This is dramatically less expensive to render than the full TextItem, so this should be used
 * in any case where we want to show a text item exclusively for display purposes.
 */
export const TextItemStatic = forwardRef(function TextItemStatic(props: IProps, ref: ForwardedRef<HTMLDivElement>) {
  return (
    <TextItemBase
      {...props}
      ref={ref}
      Text={<RichTextRender className={style.richTextRender} richText={props.defaultValue ?? EMPTY_RICH_TEXT} />}
    />
  );
});

export default TextItem;
