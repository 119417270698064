import { libraryComponentFoldersListAtom } from "@/stores/ComponentFolders";
import {
  componentNameAtom,
  linkComponentActionAtom,
  publishComponentActionAtom,
  regenerateComponentNameActionAtom,
  unwrappedSuggestedComponentIdsAtom,
} from "@/stores/ComponentLinkingFlow";
import { libraryComponentFamilyAtom } from "@/stores/Components";
import { clearLibraryInteractionViewActionAtom, derivedOnlySelectedTextItemAtom } from "@/stores/ProjectSelection";
import { usersByIdAtom } from "@/stores/Workspace";
import { PublishToLibrary, usePublishToLibrary } from "@ds/organisms/PublishToLibrary";
import * as DittoEvents from "@shared/ditto-events";
import { useDittoEventListener } from "@shared/ditto-events/frontend";
import { useAtomValue, useSetAtom } from "jotai";
import React, { useEffect, useState } from "react";
import style from "./style.module.css";

export default function PublishToLibraryWrapper() {
  const clearLibraryInteractionView = useSetAtom(clearLibraryInteractionViewActionAtom);
  const publishComponent = useSetAtom(publishComponentActionAtom);
  const textItem = useAtomValue(derivedOnlySelectedTextItemAtom);
  const _suggestedComponentIds = useAtomValue(unwrappedSuggestedComponentIdsAtom);
  const [suggestedComponentIds, setSuggestedComponentIds] = useState<string[]>(_suggestedComponentIds);

  useEffect(() => {
    setSuggestedComponentIds([..._suggestedComponentIds]);
  }, [_suggestedComponentIds]);

  useDittoEventListener(
    DittoEvents.libraryComponentsDeleted,
    function handleLibraryComponentsDeleted(data) {
      const deletedEntityIds = new Set(data.componentIds);
      setSuggestedComponentIds((prev) => prev.filter((id) => !deletedEntityIds.has(id)));
    },
    []
  );

  const regenerateComponentName = useSetAtom(regenerateComponentNameActionAtom);
  const linkComponent = useSetAtom(linkComponentActionAtom);

  if (!textItem) {
    throw new Error("Cannot publish to library without a single text item selected");
  }

  const onCancel = () => {
    clearLibraryInteractionView();
  };

  const onPublish = async (data: { name: string; folderId: string | null }) => {
    publishComponent({
      name: data.name,
      textItemId: textItem._id,
      folderId: data.folderId ?? undefined,
    });

    clearLibraryInteractionView();
  };

  const onRegenerateComponentName = () => {
    regenerateComponentName(null);
  };

  const publishToLibraryProps = usePublishToLibrary({
    onCancel,
    onPublish,
    onRegenerateComponentName,
    textItem: {
      tags: textItem.tags,
      status: textItem.status,
      defaultValue: textItem.rich_text,
      notes: textItem.notes,
    },
    componentTextMatchSuggestions: {
      usersByIdAtom,
      libraryComponentFamilyAtom,
      componentIds: suggestedComponentIds,
      onComponentActionClick: linkComponent,
      originalRichText: textItem.rich_text,
    },
    foldersAtom: libraryComponentFoldersListAtom,
    componentNameAtom,
  });

  return (
    <div className={style.wrapper}>
      <PublishToLibrary {...publishToLibraryProps} />
    </div>
  );
}
